import React, { useEffect, useState } from 'react';
import { backendApiUrl } from '../config';
import { useSecurityContext } from '../hooks';
import { Space, Table, Tag, Avatar, Tooltip, Input } from 'antd';
import { Alert, Button, Card, Container } from '../atoms';
import { Link } from 'react-router-dom';
import { AddNewDashboardModal } from '../components/DashboardPipeline/AddNewDashboardModal';
import { UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';

const { Search } = Input;

const ExternalLink = styled.a`
&& {
    color: rgba(0,0,0,.45);
    &:hover {
        opacity: 0.8;
        textDecoration: 'underline';
      }
}
`;


export function PipelinesDashboardsSummaryPage() {

  const [dashboards, setDashboards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchedText, setSearchedText] = useState('');
  const { currentToken } = useSecurityContext();

  const getDashboardsFromDb = () => {
    setIsLoading(true)
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
    };
    fetch(`${backendApiUrl}/dashboards`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setIsLoading(false)
            setDashboards(data.map(dash => {dash['key'] = dash['url']; return dash}))
        });
  }

  useEffect(() => {
    getDashboardsFromDb();
  }, [])
  

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => <Link to={`/pipelines/dashboard/${record.id}`}>{text}</Link>,
      filteredValue: [searchedText],
      onFilter: (value, record) => ['title', 'user_name'].map(key => record[key].toLowerCase().includes(value.toLowerCase())).includes(true),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, { status }) => (
        <>{
              <Tag color={status === 'in dev' ? 'purple' : status === 'in prod' ? 'green' : 'cyan'} key={status}>
                {status.toUpperCase()}
              </Tag>
          }
        </>
      ),
    },
    {
      title: 'Owner',
      dataIndex: 'user_id',
      key: 'owner',
      render: (text, record) => <Tooltip title={record.user_name}>
                                {record.user_photo ? <Avatar 
                                  src={<img src={record.user_photo} referrerPolicy="no-referrer"/>} 
                                  size="large" 
                                  icon={<UserOutlined />} 
                                /> : <Avatar size="large">{record.user_name[0].toUpperCase()}</Avatar>}
                                </Tooltip>,
      filteredValue: [searchedText],
      onFilter: (value, record) => ['title', 'user_name'].map(key => record[key].toLowerCase().includes(value.toLowerCase())).includes(true),
    },
    {
      title: 'Tables Linked',
      dataIndex: 'queries',
      key: 'queries',
      width: 620,
      render: (text, record) => 
        record.queries.map(query => `cube_reporting.${query.bq_table_name}`).join(', '),
    },
    {
      title: 'Pipeline Scheduled Start Date HKT',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (text) => moment(text).format('llll')
    },
    {
      title: 'Last Updated (in Exodus) HKT',
      dataIndex: 'updated',
      key: 'updated',
      render: (text) => moment(text).format('llll')
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <ExternalLink target='_blank' rel='noopener noreferrer' href={record.url}>{`Open in ${record.type}`}</ExternalLink>
        </Space>
      ),
    },
  ];

  const openNewReportModal = () => {
    setIsModalOpen(true);
  }

  return (
    <Container style={{background: '#fff', padding: '16px', borderRadius: '8px'}}>
      <Space 
        direction="vertical" 
        size="middle"
        style={{
          display: 'flex',
        }}
      >
        <Alert 
          message="Dashboards and Reports pipelines"
          description={
            <ReactMarkdown linkTarget="_blank">
              {`This page lists the dashboards that are or will be productionised.\n\n Each dashboard has datasources that we create via Exodus.\n\n We then create the Airflow dag from these tables.\n\n Each airflow dag configuration corresponds to one row in the below table.\n\n👉 [**Process documentation**](https://www.notion.so/data-scmp/Dashboard-creation-process-69384aac40664788a50e78f4e08d6dd6)
              \n\n👉 [**Golden rules of Looker Studio**](https://www.notion.so/data-scmp/Golden-rules-of-Data-Studio-8b6aae8f2a76412cb1932191a4d747a9)`}
            </ReactMarkdown>
          }
          type="info" 
          showIcon 
        />
       
          <Button type="primary" style={{marginTop: 16}} onClick={openNewReportModal}> Add Dashboard</Button>
          <Search 
            placeholder='Search on Title or Owner Name...' 
            style={{margin:'auto', marginBottom: 8, width: '40%'}}
            onSearch={(value) => setSearchedText(value)}
            onChange={(e) => setSearchedText(e.target.value)}
          >
          </Search>
          <Table
            bordered
            columns={columns} 
            dataSource={dashboards} 
            loading={isLoading}
          />
        <AddNewDashboardModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} getDashboardsFromDb={getDashboardsFromDb} dashboardsFromDb={dashboards}/>
      </Space>
    </Container>
  );
}