import { Result } from 'antd';
import { Button } from '../atoms';
import { useHistory } from 'react-router-dom';

export function NoMatch() {
    const { push } = useHistory();
    const onClick = () => {
        push('/');
    }
    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary" className="main-button" onClick={onClick}>Back Home</Button>}
        />
    );
  }