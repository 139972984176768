import { useState } from 'react';
import { Space, Tooltip,  Button } from 'antd';
import  { CopyOutlined, LinkOutlined } from '@ant-design/icons';
import CSVExport from './CSVExport';
import { copyToClipboard, copyTable } from '../../utils';
import { useDeepEffect } from '../../hooks';


export default function Export({
  disabled,
  isQueryPresent,
  chartOption,
  csvData,
  csvHeader
}) {
  const [formattedData, setFormattedData] = useState([]);

  useDeepEffect(() => {
    const newCsvData = csvData.map(row => {
      const result = Object.fromEntries(
        Object.entries(row).map(([k, v]) => {
          const lookedUpColumn = csvHeader.filter(col => col.key === k)[0];
          if(lookedUpColumn['type'] === 'time' && v){
            v = v.replace('T00:00:00.000', '')
          }
          return [lookedUpColumn['shortTitle'], v]
        })
      )
      return result
    })
    setFormattedData(newCsvData)
  }, [csvData])

  return (
    <Space>
      <CSVExport data={formattedData} disabled={disabled}/>
      <Tooltip title="Copy Data">
        <Button 
          type="secondary" 
          shape="round" 
          icon={<CopyOutlined />}
          onClick={async () => {
            
            await copyTable('The data has been copied');
          }}
          disabled={formattedData.length === 0 || disabled || chartOption !== 'table'}
         />
      </Tooltip>
      <Tooltip title="Copy Query Link">
        <Button 
          type="secondary" 
          shape="round" 
          icon={<LinkOutlined />} 
          onClick={async () => {
            await copyToClipboard(window.location.href, 'The url has been copied');
          }}
          disabled={!isQueryPresent || disabled}
        />
      </Tooltip>
    </Space>
  );
}