import React, { useEffect, useState } from 'react';
import { Form, Modal, Input, Switch, Select, notification, Button, Tooltip } from 'antd';
import { useSecurityContext } from '../../hooks';
import { backendApiUrl } from '../../config';
import { SaveTwoTone, SyncOutlined } from '@ant-design/icons';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { Link } from 'react-router-dom';


export function SaveQueryModal({ activeId, tabs, saveTabs, isModalOpen, setIsModalOpen, saveQueryToDb, tabMode }) {

  const [form] = Form.useForm();
  const [userDepartment, setUserDepartment] = useState(null);
  const [currentTab, setCurrentTab] = useState({tabName: 'Query 1'})
  const [inputValue, setInputValue] = useState('');
  const [bqTableName, setBQTableName] = useState('');
  const [saveToBQ, setSaveToBQ] = useState(false);
  const [canSaveToBQ, setCanSaveToBQ] = useState(false);
  const [tableExists, setTableExists] = useState(false);
  const [rawSql, setRawSql] = useState('');
  const [schema, setSchema] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const { getUserDepartment, currentToken } = useSecurityContext();
  const [dashboards, setDashboards] = useState([]);
  const [selectedDashboardId, setSelectedDashboardId] = useState(null);


  const getDashboardsFromDb = () => {
    setIsSpinning(true);
    const payload = jwtDecode(currentToken);
    const sub = payload.sub;
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
    };
    fetch(`${backendApiUrl}/dashboards`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setDashboards(data.map(dash => {dash['key'] = dash['url']; return dash}))
            setIsSpinning(false);
        });
  }

  const checkIfDatasetExists = () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
    };

    const payload = jwtDecode(currentToken);
    const username = payload.user_email.split('@')[0].replace('.', '_');
    const dataset = `test_${username}`

    fetch(`${backendApiUrl}/bigquery/dataset/${dataset}`, requestOptions)
    .then(response => response.json())
    .then(data => setCanSaveToBQ(Object.keys(data)[0] === 'message'))
  }

  const checkIfTableAlreadyExists = async (dataset, table) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
    };

    fetch(`${backendApiUrl}/bigquery/dataset/${dataset}/table/${table}`, requestOptions)
    .then(response => response.json())
    .then(data => {
      if ('error' in data){
        console.log(data)
        setTableExists(true)
      } else {
        setTableExists(false)
      }
    })
  }

  const saveQueryToBQTable = (table_name, partition_column) => {
    setConfirmLoading(true);
    const payload = jwtDecode(currentToken);
    const username = payload.user_email.split('@')[0].replace('.', '_');
    const dataset = selectedDashboardId ? `cube_reporting` : `test_${username}`;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
      body: JSON.stringify({
          query: rawSql,
          table_name: table_name,
          partition_column: partition_column,
          dataset_name: dataset
      })
    }
    fetch(`${backendApiUrl}/bigquery/save`, requestOptions)
      .then(response => response.json())
      .then(data => {
        setConfirmLoading(false);
        setIsModalOpen(false);
        const message = `Table \`${dataset}.${table_name}\` created!`
        notification.open({
          message: message,
          icon: <SaveTwoTone />,
          duration: 10,
          style: {width: `${Math.max(84, message.length * 10)}px`}
        })
      })
  }

  const addQueryToDashboard = (queryId) => {
    const selectedDashboard = dashboards.find(dash => dash.id === selectedDashboardId);
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
      body: JSON.stringify({
          title: selectedDashboard.title,
          url: selectedDashboard.url,
          type: 'Looker Studio',
          status: selectedDashboard.status,
          start_date: moment(selectedDashboard.start_date).utc().format('YYYY-MM-DDTHH:mm:ss'),
          queries: 'queries' in selectedDashboard ? selectedDashboard.queries.map(query => query.id).concat(queryId) : [queryId],
      })
    }
    fetch(`${backendApiUrl}/dashboard/${selectedDashboardId}`, requestOptions)
      .then(response => response.json());
  }
  
  const handleSubmit = async (values) => {
    try {
        const values = await form.validateFields();
    } catch (errorInfo) {
        return;
    }
    if(values.name !== currentTab.tabName){
        tabs = tabs.map(tab => {
            if(tab.id === currentTab.id){
              return { ...tab, tabName: values.name }
            }
            return tab
          })
        saveTabs({activeId: currentTab.id, tabs})
    }
    
    if (saveToBQ){
      const is_bq_table_productionised = selectedDashboardId ? true : false;
      if(selectedDashboardId){
        // Validate table does not yet exist
        await checkIfTableAlreadyExists('cube_reporting', values.bq_table_name);
      }
      if(!tableExists){
        const queryId = await saveQueryToDb(tabMode, {
          name: values.name,
          tab: currentTab,
          bq_table_name: values.bq_table_name, 
          partition_name: values.partition_column,
          is_bq_table_productionised: is_bq_table_productionised,
        });
        saveQueryToBQTable(values.bq_table_name, values.partition_column)
        addQueryToDashboard(queryId)
      }
    } else {
      await saveQueryToDb(tabMode, {name: values.name, tab: currentTab});
      setIsModalOpen(false);
    }
  }
  
  const handleCancel = () => {
    setIsModalOpen(false);
    setSaveToBQ(false);
    form.resetFields();
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (e.target.id === 'bq_table_name'){
      setBQTableName(e.target.value)
      if(selectedDashboardId){
        checkIfTableAlreadyExists('cube_reporting', e.target.value);
      }
    }
    
  }

  useEffect(() => {
    setConfirmLoading(false);
    getDashboardsFromDb();
    setUserDepartment(getUserDepartment());
    checkIfDatasetExists()
  }, [])

  useEffect(() => {
    setCurrentTab(tabs.filter(tab => tab.id === activeId)[0]);
    form.setFieldsValue({name: tabs.filter(tab => tab.id === activeId)[0].tabName});
  }, [activeId, tabs])

  useEffect(() => {
    setInputValue(currentTab.tabName);
    if (currentTab.query && Object.keys(currentTab.query).includes('timeDimensions')){
      // get SQL from Cube query
      let requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
      }
      const jsonQuery = JSON.stringify(currentTab.query)
      fetch(`${process.env.REACT_APP_CUBEJS_API_BASE_URL}/v1/sql?query=${jsonQuery}`, requestOptions)
        .then(response => response.json())
        .then(data => {
          if (data && data.sql){
            const filters = data.sql.sql[1];
            filters.forEach(filter_ => {
              data.sql.sql[0] = data.sql.sql[0].replace(/[?]/, "'" + filter_ + "'")
            })
          }
          return data.sql.sql[0]
        })
        .then(data => setRawSql(data))
    }
  }, [isModalOpen])

  useEffect(() => {
     // fetch BQ query schema
    if(rawSql){
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
        body: JSON.stringify({ 
            query: rawSql,
        })
      }
      fetch(`${backendApiUrl}/bigquery/schema`, requestOptions)
        .then(response => response.json())
        .then(data => {
          const partitionFields = ['run_date'].concat(data.filter(field => ['TIMESTAMP', 'DATE', 'DATETIME'].includes(field.type)).map(field => field.name))
                                              .map(field => ({value: field, label: field}))
          setSchema(partitionFields)
        })
    }
  }, [rawSql])


  const onSwitch = (checked) => {
    setSaveToBQ(checked)
  }

  return (
    <Modal 
      title="Save As" 
      open={isModalOpen} 
      onOk={form.submit} 
      onCancel={handleCancel} 
      okText="Save"
      confirmLoading={confirmLoading}
      width={640}
    >
        <Form 
            form={form}
            layout="vertical"
            initialValues={{
                name: currentTab.tabName || '',
                save_bq: false,
            }}
            onFinish={handleSubmit}
            // onValuesChange={handleChecks}
        >
            <Form.Item
                label="Name"
                name="name"
                rules={[
                    {
                        required: true,
                        message: `Please input a name for your ${tabMode}!`,
                    },
                ]}
            >
                <Input value={inputValue} onChange={handleInputChange}/>
            </Form.Item>
            {tabMode !== "audience" && canSaveToBQ && <Form.Item
                label="Also save as BQ table?"
                name="save_bq"
                // style={{paddingBottom: 20}}
            >
                <Switch onChange={onSwitch}/>
            </Form.Item>}
            {saveToBQ && <Form.Item
                label="Table Name"
                name="bq_table_name"
                hasFeedback={tableExists}
                validateStatus={tableExists ? "error" : "validating"}
                help={tableExists ? "Nope... Table already exists in BQ... Please give another name" : ""}
                rules={[
                    {
                        required: true,
                        message: 'Please input a name for your table!',
                    },
                ]}
            >
                <Input value={inputValue} onChange={handleInputChange}/>
            </Form.Item>}
            <div style={{height: 20}}></div>
            {saveToBQ && <Form.Item
                label="Choose a partition field"
                name="partition_column"
                rules={[
                    {
                        required: true,
                        message: 'Please choose a partition!',
                    },
                ]}
            >
                <Select
                  style={{
                    width: 240,
                  }}
                  options={schema}
                />
            </Form.Item>}

            {saveToBQ && userDepartment === 'Consumer Subscription, Audience & Data' && <Form.Item
                label={<>Add to pipeline requirements for dashboard</>}
                name="dashboard"
                rules={[
                    {
                        required: false,
                    },
                ]}
                requiredMark="optional"
                help={<><p>
                Select a dashboard pipeline for saving your table under <b>cube_reporting</b> dataset. Don't see your dashboard? Add it in the <Link target='_blank' rel='noopener noreferrer' to="/pipelines">dashboard pipelines</Link> screen first
                
                </p>
                <p style={{marginTop: 0}}>Refresh the dropdown after adding a dashboard <Tooltip title="refresh">
                  <Button shape="circle" size="small" icon={<SyncOutlined spin={isSpinning}/>} onClick={getDashboardsFromDb} />
                </Tooltip></p></>}
            >
                <Select
                  options={dashboards.map(dash => ({value: dash.id, label: dash.title}))}
                  onChange={(value) => {
                    setSelectedDashboardId(value);
                    checkIfTableAlreadyExists('cube_reporting', bqTableName);
                  }}
                />
            </Form.Item>}
            
        </Form>
    </Modal>
  );
}