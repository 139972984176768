const colors = {
    navy: '#3F88FE',
    blue: '#003296',
    'purple-03': '#004BBB',
    'purple-04': '#3F88FE',
    text: '#5B5C7D',
    'dark-01': '#1A2129',
    'dark-02': '#001246',
    'dark-03': '#78909C',
    'dark-04': '#78909C',
    'dark-05': '#d9d9d9',
    light: '#FAFAFA',
    // green: '65, 181, 111',
    yellow: '#FFCB05',
    gray: '#595959',
  };

  const hexToRgb = hex =>
  hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
             ,(m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1).match(/.{2}/g)
    .map(x => parseInt(x, 16))
  
  function color(name, opacity = 1) {
    return `rgba(${hexToRgb(colors[name])}, ${opacity})`;
  }

  const VARIABLES = {
    'active-bg': color('navy', 0.1),
    'primary-bg': color('navy', 0.1),
    'primary-1': color('navy', 0.9),
    'primary-2': color('navy', 0.8),
    'primary-3': color('navy', 0.7),
    'primary-4': color('navy', 0.6),
    'primary-5': color('navy', 0.5),
    'primary-6': color('navy', 0.4),
    'primary-7': color('navy', 0.3),
    'primary-8': color('navy', 0.2),
    'primary-9': color('navy', 0.1),
    'primary-10': color('navy', 0),
    'light-5': color('light', 0.5),
    'remove-btn-bg': color('navy', 0.1),
    'remove-btn-hover-bg': color('navy', 0.2),
    'primary-color': color('navy'),
    'primary-9-color': color('navy', 0.1),
  
    'success-bg-color': color('navy', 0.1),
    'success-color': color('navy', 0.9),
    'warning-bg-color': color('yellow', 0.1),
    'warning-color': color('yellow', 0.9),
  
    'yellow-8': color('yellow', 0.2),
    'yellow-9': color('yellow', 0.1),
  
    'heading-color': color('dark-01', 0.65),
    'link-color': color('navy'),
    'info-color': color('navy'),
    'layout-body-background': '#f0f5ff;',
    'layout-header-background': '#eeeef5',
    'menu-highlight-color': color('dark-01'),
    'item-hover-bg': color('light'),
    'layout-header-height': '48px',
  
    'font-family':
      'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Avenir Next", Inter, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
    'menu-item-font-size': '15px',
    'btn-primary-shadow': 'none',
    'btn-text-shadow': 'none',
    'modal-body-padding': '32px',
    'form-item-margin-bottom': '23px',
  
    'disabled-color': color('dark-01', 0.25),
    'disabled-bg': color('dark-05', 0.2),
  
    // menu
    'menu-item-active-bg': color('light'),
  
    'font-size-base': '14px',
    'border-radius-base': '4px',
    'padding-lg': '16px',
  };
  
  Object.keys(colors).forEach(
    (name) => {
      VARIABLES[`${name}-color`] = color(name);
      VARIABLES[`${name}-color-rgb`] = hexToRgb(colors[name]);
    }
  );
  
  const LESS_VARIABLES = {};
  const CSS_PROPERTIES = {};
  
  Object.keys(VARIABLES).forEach((key) => {
    LESS_VARIABLES[`@${key}`] = VARIABLES[key];
  });
  
  Object.keys(VARIABLES).forEach((key) => {
    CSS_PROPERTIES[`--${key}`] = VARIABLES[key];
  });
  
  export { VARIABLES, LESS_VARIABLES, CSS_PROPERTIES };