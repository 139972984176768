import { InfoCircleOutlined as AntdInfoCircleOutlined } from '@ant-design/icons';
import { Dropdown, Layout, Menu, Col, Row, Typography, Button } from 'antd';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { CubejsIcon } from '../../shared/icons';

const StyledHeader = styled(Layout.Header)`
  && {
    color: #001246;
    padding: 0;
    line-height: 44px;
    ${'' /* border-bottom: 0.3em solid;
    border-bottom-color: var(--yellow-color); */}
    display: flex;
    flexDirection: row;
    justifyContent: center;
    alignItems: center;
    height: 48px;
    background-color: transparent;
  }
`;

const { Title } = Typography;


export default function Header({ collapsed }) {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 992px)',
  });

  const isMobileOrTable = useMediaQuery({
    query: '(max-device-width: 991px)',
  });

  return (
    <StyledHeader>
      <Col style={{ float: 'left'}}>
        <Row style={{ paddingLeft: collapsed ? 26 : 16, background: 'transparent', width: '200px', paddingTop: 16, paddingBottom: collapsed ? 15 : 2 }}>
            <CubejsIcon/> 
            {!collapsed && <Title level={3} style={{color: '#001246', marginBottom: 0, marginLeft: 4, }}>Exodus</Title>}
        </Row>
      </Col>
      
    </StyledHeader>
  );
}