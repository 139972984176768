import { Dropdown, Row, Typography } from 'antd';
import  { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { googleLogout } from '@react-oauth/google';
import { useSecurityContext } from '../../hooks';
import styled from 'styled-components';

export function UserAvatar({collapsed, setCollapsed}) {

    const { removeCubeToken, removeGoogleRefreshRes, removeGoogleFullRes } = useSecurityContext()
    
    const logout = () => {
        googleLogout();
        removeCubeToken();
        removeGoogleRefreshRes();
        removeGoogleFullRes();
        setCollapsed(false);
    }
    

    const CustomRow = styled(Row)`
    && {
        :hover {
          color: #1890ff;
      }
    }
  `;

  const CustomTypography = styled(Typography)`
  && {
        :hover {
          color: #1890ff;
      }
    }
  `
    

    return (
          <CustomRow onClick={e => logout()} justify='center' align='middle' style={{cursor: 'pointer', position: 'fixed', bottom: 80, left: collapsed ? 28 : 20 }}>
            <LogoutOutlined />
            {!collapsed && <CustomTypography style={{marginLeft: 4}}>Log out</CustomTypography>}
          </CustomRow>)
}