import { useEffect, useRef, useState, useMemo } from 'react';
import { Input, Menu as AntdMenu, Popover } from 'antd';
import { InfoCircleOutlined as AntdInfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import FlexSearch from 'flexsearch';
import ReactMarkdown from 'react-markdown';

import ButtonDropdown from './ButtonDropdown';
import { useDeepMemo } from '../../hooks';
import { getNameMemberPairs } from '../../shared/members';
import { getMembersByView } from '../../config';
import { useQueryContext } from '../QueryContext';

const Menu = styled(AntdMenu)`
  max-height: 320px;
  overflow: hidden auto;
  padding-top: 0;
  li.ant-dropdown-menu-item-active {
    background: var(--primary-9-color);
  }
`;

const InfoCircleOutlined = styled(AntdInfoCircleOutlined)`
  color: var(--disabled-color);
  &:hover {
    color: var(--primary-5-color);
  }
`;

const SearchMenuItem = styled(Menu.Item)`
  position: sticky;
  top: 0;
  z-index: 1;
  // this isn't the best solution ever, but according to the situation other solutions are worse
  // antd uses double class pattern (.disabled.active.active) to override the value of background color. actually the
  // easiest way to override it is to use smtn with higher specificity
  background: white !important;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 8px;
  cursor: default;
  ::after {
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    bottom: -20px;
    height: 20px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
  }
`;

const TooltipMarkdown = styled(ReactMarkdown)`
  white-space: pre-wrap;
`

function filterMembersByKeys(
  members,
  keys
) {
  const cubeNames = keys.map((key) => key.split('.')[0]);
  return members
    .filter(({ cubeName }) => cubeNames.includes(cubeName))
    .map((cube) => {
      return {
        ...cube,
        members: cube.members.filter(({ name }) => keys.includes(name)),
      };
    });
}

function visibilityFilter({ isVisible, meta }) {
  return isVisible && !('shown' in meta && !meta.shown);
}

export default function MemberMenu({
  availableCubes,
  showNoMembersPlaceholder = true,
  addMemberName,
  onClick,
  ...buttonProps
}) {
  const searchInputRef = useRef(null);
  // const index = new Fuse(getNameMemberPairs(availableCubes).map(pair => pair[1]), {keys: ['name', 'title']})
  const index = useRef(new FlexSearch.Index({ tokenize: 'full' })).current;
  const [search, setSearch] = useState('');
  const [filteredKeys, setFilteredKeys] = useState([]);
  const { GASchema, mode } = useQueryContext();

  const hasMembers = availableCubes.some(
    (cube) => cube.members.filter(visibilityFilter).length > 0
  );

  const indexedMembers = useMemo(() => {
    if(!buttonProps.disabled){
      getNameMemberPairs(availableCubes).forEach(([name, { title }]) =>
        index.add(name, title)
      );
      return Object.fromEntries(getNameMemberPairs(availableCubes));
    }
  }, [buttonProps.disabled, availableCubes]);

  useEffect(() => {
    let currentSearch = search;
    (async () => {
      const results = await index.search(search);
      if (currentSearch !== search) {
        return;
      }
      setFilteredKeys(results);
    })();

    return () => {
      currentSearch = '';
    };
  }, [index, search]);

  const members = search
    ? getMembersByView(GASchema, mode, filterMembersByKeys(availableCubes, filteredKeys))
    : getMembersByView(GASchema, mode, availableCubes);

  return (
    <ButtonDropdown
      {...buttonProps}
      onOpenChange={async () => {
        setSearch('')
      }}
      onClick={() => {
        // we need to delay focusing since React needs to render <Menu /> first :)
        setTimeout(() => {
          searchInputRef.current?.focus({ preventScroll: true });
        });
      }}
      overlay={
        <Menu
          onKeyDown={(e) => {
            if (
              [
                'ArrowDown',
                'ArrowUp',
                'ArrowLeft',
                'ArrowRight',
                'Enter',
                'Escape',
                'Tab',
                'CapsLock',
              ].includes(e.key)
            ) {
              return;
            }

            if (document.activeElement === searchInputRef.current?.input){
              return;
            }       

            searchInputRef.current?.focus({ preventScroll: true });
          }}
          onClick={(event) => {
            if (['__not-found__', '__search_field__'].includes(event.key)) {
              return;
            }

            setSearch('');
            setFilteredKeys([]);
            onClick(indexedMembers[event.key]);
          }}
        >
          {hasMembers ? (
            <>
              {addMemberName !== 'User ID' && <SearchMenuItem disabled key="__search_field__">
                <Input
                  ref={searchInputRef}
                  placeholder="Search"
                  autoFocus
                  allowClear
                  onKeyDown={(event) => {
                    if (['ArrowDown', 'ArrowUp', 'Enter'].includes(event.key)) {
                      event.preventDefault();
                    }

                    if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
                      event.stopPropagation();
                    }
                  }}
                  onChange={(event) => {
                    setSearch(event.target.value);
                  }}
                />
              </SearchMenuItem>}

              {members.map((cube) => {
                const members = cube.members.filter(visibilityFilter);
                if (!members.length || !('meta' in members[0])) {
                  return null;
                }

                return (
                  <Menu.ItemGroup key={members[0].meta.view} title={members[0].meta.view}>
                    {members.map((m) => (
                      <Menu.Item key={m.name} data-testid={m.name}>
                        <div style={{display: 'flex', justifyContent:'space-between', alignItems: 'center'}}>
                          {m.shortTitle}
                          {m.description &&
                           <Popover 
                              overlayStyle={{zIndex: '9900'}} 
                              overlayInnerStyle={{paddingBottom: '8px', paddingRight: '8px', paddingLeft: '8px', maxWidth: '320px'}} 
                              placement="right" 
                              title={m.shortTitle} 
                              content={<TooltipMarkdown linkTarget="_blank">{m.description}</TooltipMarkdown>} 
                              trigger="hover"
                            >
                            <InfoCircleOutlined style={{marginLeft: '12px'}} />
                           </Popover>}
                        </div>
                      </Menu.Item>
                    ))}
                  </Menu.ItemGroup>
                );
              })}
            </>
          ) : showNoMembersPlaceholder ? (
            <Menu.Item key="__not-found__" disabled>
              No members found
            </Menu.Item>
          ) : null}
        </Menu>
      }
    />
  );
}