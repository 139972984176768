import React, { useEffect, useState } from 'react';
import { Modal, List, Skeleton, notification, Input, Select, Space, Form, Button, Steps, Typography } from 'antd';
import { backendApiUrl } from '../../config';
import { useSecurityContext } from '../../hooks';
import { SaveTwoTone } from '@ant-design/icons';
import styled from 'styled-components';
import jwtDecode from 'jwt-decode';

const { Search } = Input;

const Link = styled.a`
&& {
    color: rgba(0,0,0,.45);
    &:hover {
        opacity: 0.8;
      }s
}
`;


export function AddNewSyncModal({ isModalOpen, setIsModalOpen, getSyncsFromDb, syncsFromDb }) {

  const {currentToken, getUserDepartment } = useSecurityContext();
  const [audiences, setAudiences] = useState([]);
  const [searchedQuery, setSearchedQuery] = useState('');
  const [step, setStep] = useState('platform');
  const [platform, setPlatform] = useState('facebook');
  const [inputAudience, setInputAudience] = useState('');
  const [frequency, setFrequency] = useState('day');
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();


  const getAudiences = () => {
    setIsLoading(true)
    const payload = jwtDecode(currentToken);
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
    };
    fetch(`${backendApiUrl}/audiences?sub=${payload.sub}`, requestOptions)
        .then(response => response.json())
        .then(data => data.filter(
          audience => !syncsFromDb.map(sync => sync.title).includes(`${audience.name} - ${platform}`) && audience.name.toLowerCase().includes(searchedQuery.toLowerCase())
      ))
        .then(data => {
            setIsLoading(false)
            setAudiences(data)
            setInputAudience(data[0].id)
        });
  }

  useEffect(() => {
    getAudiences();
  }, [syncsFromDb])
  
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleClickOpen = (item) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
        body: JSON.stringify({
            title: `${audiences.find(aud => aud.id === item.audience).name} - ${item.platform}`,
            audience: item.audience,
            platform: item.platform,
            frequency: item.frequency
        })
      }
      fetch(`${backendApiUrl}/syncs`, requestOptions)
        .then(response => response.json())
        .then(data => {
          const message = `Sync ${audiences.find(aud => aud.id === item.audience).name} - ${item.platform} added!`
          notification.open({
            message: message,
            icon: <SaveTwoTone />,
            duration: 10,
            style: {width: `${Math.max(84, message.length * 10)}px`}
          })
        })
        .then(data => {
            getSyncsFromDb();
        })
    setIsModalOpen(false);
  }

  const handleFormSubmit = async (values) => {
    try {
        const values = await form.validateFields();
    } catch (errorInfo) {
        return;
    }
    handleClickOpen({platform, audience: inputAudience, frequency})
  }

  const handleNextStep = async () => {
    if (step === 'platform'){
        setStep('audience')
    } else if (step === 'audience'){
        setStep('frequency')
    } else {
        setStep('platform')
    }
  }

  const handlePreviousStep = async () => {
    if (step === 'frequency'){
      setStep('audience')
  } else if (step === 'audience'){
      setStep('platform')
  } else {
      setStep('frequency')
  }
  }

  const onAudienceChange = (value) => {
    setInputAudience(value)
  };
  const onAudienceSearch = (value) => {
    console.log('search:', value);
  };

  const stepToDigit = {
    platform: 0,
    audience: 1,
    frequency: 2
  }

  return (
    <Modal 
        title="Configure your sync" 
        open={isModalOpen} 
        footer={step === 'frequency' ? [<Button key="cancel" onClick={handlePreviousStep}>
            Previous
          </Button>,
          <Button key="submit" type="primary" onClick={handleFormSubmit}>
            Add
          </Button>] : step === 'audience' ? [<Button key="previous" onClick={handlePreviousStep}>
            Previous
          </Button>,
          <Button key="submit" type="primary" onClick={handleNextStep}>
            Next
          </Button>] : [<Button key="previous" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleNextStep}>
            Next
          </Button>]} 
        okText='Add' 
        onOk={form.submit} 
        onCancel={handleCancel} 
        width={800}
    >
        <Space direction='vertical' size={'large'} style={{width: '100%'}}>
            <Steps
                size="small"
                current={stepToDigit[step]}
                items={[
                    {
                        title: 'Platform',
                        description: ['audience', 'frequency'].includes(step) ? platform : ''
                    },
                    {
                        title: 'Audience',
                        description: ['frequency'].includes(step) ? audiences.find(aud => aud.id === inputAudience).name : ''
                    },
                    {
                        title: 'Frequency',
                    },
                ]}
            />
        {step === 'audience' ? 


        <Space style={{marginTop: 36}}>
            <Typography>Choose an audience:</Typography>
            <Select
              showSearch
              placeholder="Select an audience"
              optionFilterProp="children"
              onChange={onAudienceChange}
              onSearch={onAudienceSearch}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={audiences.map(aud => ({label: aud.name, value: aud.id}))}
              style={{width: 300}}
              value={inputAudience}
            />
          </Space>
        : step === 'platform' ? <Space style={{marginTop: 36}}>
            <Typography>Choose a platform:</Typography>
            <Select
                options={[
                    {
                        label: 'Facebook',
                        value: 'facebook',
                    },
                    {
                        label: 'Batch',
                        value: 'batch',
                        disabled: true,
                    },
                ]}
                value={platform}
                onChange={setPlatform}
                style={{width: 200}}
            />
          </Space>
        : <Space style={{marginTop: 36}}>
            <Typography>Choose a frequency:</Typography>
            <Select
                options={[
                    {
                        label: 'Daily',
                        value: 'day',
                    },
                    {
                        label: 'Weekly',
                        value: 'week',
                    },
                    {
                        label: 'Monthly',
                        value: 'month',
                    },
                ]}
                value={frequency}
                onChange={setFrequency}
                style={{width: 200}}
            />
          </Space>}
      </Space>
    </Modal>
  );
}