import { Dropdown } from 'antd';

import { Button } from '../../atoms';

const ButtonDropdown = ({
  overlay,
  disabled = false,
  onOpenChange=()=>{},
  ...buttonProps
}) => {
  return (
    <Dropdown
      disabled={disabled}
      overlay={overlay}
      destroyPopupOnHide
      placement="bottomLeft"
      trigger={['click']}
      onOpenChange={onOpenChange}
    >
      <Button {...buttonProps} disabled={disabled} />
    </Dropdown>
  );
};

export default ButtonDropdown;