import styled from 'styled-components';
import { Card as AntdCard } from 'antd';

const StyledCard = styled(AntdCard)`
  && {
    border: none;
    min-height: 100%;
    ${'' /* min-width: 80%; */}
    background: var(--layout-body-background);
    & .ant-card-body {
        padding: 0;
    }
    @media only screen and (min-width: 1200px) {
        width: 90%;
    }
    @media only screen and (min-width: 1600px) {
        width: 80%;
    }
  }
`;

export { StyledCard as Container };