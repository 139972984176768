import React, { useEffect, useState } from 'react';
import { backendApiUrl } from '../config';
import { useSecurityContext } from '../hooks';
import { Space, Table, Tag, Avatar, Tooltip, Input, Typography, Col } from 'antd';
import { Container, Alert, Button } from '../atoms';
import { Link } from 'react-router-dom';
import { AddNewSyncModal } from '../components/SyncPipeline/AddNewSyncModal';
import { UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import jwtDecode from 'jwt-decode';

const { Search } = Input;

const {Text} = Typography;


export function PipelinesSyncsSummaryPage() {

  const [syncs, setSyncs] = useState([]);
  const [audiences, setAudiences] = useState([{id: 0}]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAudiencesLoading, setIsAudiencesLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchedText, setSearchedText] = useState('');
  const { currentToken } = useSecurityContext();
  const { push } = useHistory();

  const getSyncsFromDb = () => {
    setIsLoading(true)
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
    };
    fetch(`${backendApiUrl}/syncs`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setIsLoading(false)
            setSyncs(data.map(sync => {sync['key'] = sync['title']; return sync}))
        });
  }

  const getAudiences = () => {
    setIsAudiencesLoading(true)
    const payload = jwtDecode(currentToken);
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
    };
    fetch(`${backendApiUrl}/audiences?sub=${payload.sub}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setIsAudiencesLoading(false)
            setAudiences(data)
        });
  }

  useEffect(() => {
    getAudiences();
    getSyncsFromDb();
  }, [])
  

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => <Link to={`/pipelines/sync/${record.id}`}>{text}</Link>,
      filteredValue: [searchedText],
      onFilter: (value, record) => ['title', 'user_name'].map(key => record[key].toLowerCase().includes(value.toLowerCase())).includes(true),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, { status }) => (
        <>{
              <Tag color={status === 'in dev' ? 'purple' : status === 'in prod' ? 'green' : 'cyan'} key={status}>
                {status.toUpperCase()}
              </Tag>
          }
        </>
      ),
    },
    {
      title: 'Owner',
      dataIndex: 'user_id',
      key: 'owner',
      render: (text, record) => <Tooltip title={record.user_name}>
                                {record.user_photo ? <Avatar 
                                  src={<img src={record.user_photo} referrerPolicy="no-referrer"/>} 
                                  size="large" 
                                  icon={<UserOutlined />} 
                                /> : <Avatar size="large">{record.user_name[0].toUpperCase()}</Avatar>}
                                </Tooltip>,
      filteredValue: [searchedText],
      onFilter: (value, record) => ['title', 'user_name'].map(key => record[key].toLowerCase().includes(value.toLowerCase())).includes(true),
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      key: 'frequency',
    },
    
    {
      title: 'Pipeline Scheduled Start Date HKT',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (text) => moment(text).format('llll')
    },
    {
      title: 'Last Updated (in Exodus) HKT',
      dataIndex: 'updated',
      key: 'updated',
      render: (text) => moment(text).format('llll')
    },
  ];

  const openNewReportModal = () => {
    setIsModalOpen(true);
  }

  return (
    <Container style={{background: '#fff', padding: '16px', borderRadius: '8px'}}>
      <Space 
        direction="vertical" 
        size="middle"
        style={{
          display: 'flex',
        }}
      >
        <Alert 
          message="Syncs pipelines"
          description={
            <ReactMarkdown linkTarget="_blank">
              {`Syncs allow you to push your audiences created with Exodus to external platforms.\n\nThis page lists the syncs that are or will be productionised.`}
            </ReactMarkdown>
          }
          type="info" 
          showIcon 
        />
        {audiences.length > 0 ? 
          <Button type="primary" style={{marginTop: 16}} onClick={openNewReportModal} loading={isAudiencesLoading}>Add Sync</Button>
          : <Alert 
            message={
              <Col><Text strong>
                {`Oops... It looks like you have not created any audiences yet.`}
                
              </Text><Button type="link" onClick={() => push('/audience')}>Create an Audience</Button>
              </Col>}
            type="warning" 
            showIcon 
          />
        }
        <Search 
          placeholder='Search on Title or Owner Name...' 
          style={{margin:'auto', marginBottom: 8, width: '40%'}}
          onSearch={(value) => setSearchedText(value)}
          onChange={(e) => setSearchedText(e.target.value)}
        >
        </Search>
        <Table
          bordered
          columns={columns} 
          dataSource={syncs} 
          loading={isLoading}
        />
        <AddNewSyncModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} getSyncsFromDb={getSyncsFromDb} syncsFromDb={syncs}/>
      </Space>
    </Container>
  );
}