import { Steps } from 'antd';
import React from 'react';
import { useSecurityContext } from '../hooks';

const steps = [
  {
    title: 'G suite Login',
  },
  {
    title: 'Get Cube token',
  },
  {
    title: 'Done',
  },
];

const LoginSteps = () => {
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const { stepLoginFlow } = useSecurityContext();
  return (
    <div style={{marginBottom: '84px', marginTop: '42px', width: '620px'}}>
      <Steps current={stepLoginFlow} items={items} />
    </div>
  );
}

export default LoginSteps;