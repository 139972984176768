import React, { useState, useEffect } from 'react';
import { QueryBuilder } from '@cubejs-client/react';
import { backendApiUrl } from '../config';
import { useHistory, Link } from 'react-router-dom';
import { playgroundContext, buildApiUrl, filterMembers } from '../config';
import { Table, Row, Space, Col, Typography, Tooltip, Card as AntCard } from 'antd';
import { Card, Button, Alert, Container} from '../atoms';
import MemberGroup from '../components/QueryBuilder/MemberGroup';
import SectionHeader from '../components/SectionHeader';
import styled from 'styled-components';
import { useCubeQuery } from '@cubejs-client/react';
import TimeGroup from '../components/QueryBuilder/TimeGroup';
import { QueryContext } from '../components/QueryContext';
import { useSecurityContext } from '../hooks';
import { PlaySquareOutlined } from '@ant-design/icons';
import { capitalize } from '../utils';
import moment from 'moment';
import jwtDecode from 'jwt-decode';

const { Title } = Typography;

const StyledTitle = styled(Title)`
&.ant-typography {
  color: var(--dark-02-color);
  margin-bottom: 4px;
}
`;

const Section = styled.div`
  display: flex;
  flex-flow: column;
  margin-right: 24px;
  margin-bottom: 16px;
  > *:first-child {
    margin-bottom: 8px;
  }
`;

const StyledCard = styled(Card)`
  ${'' /* width: 100%; */}
  &.ant-card-head {
    position: sticky;
    top: 0;
    z-index: 100;
    background: white;
    border-bottom-color: #f6f6f8;
  }
  &ant-card-body {
    max-width: 100%;
    overflow: auto;
    position: relative;
  }
  .ant-tabs > .ant-tabs-nav {
    margin-bottom: 8px;
  }
`;

const StyledCell = styled.div`
    background-color: ${(props) => getBackgroundColor(props.value)};
    color: ${(props) => props.value > 30 ? '#fff' : '#000'};
    padding: 16px;
    text-align: center;
`;

const getBackgroundColor = (value) => {
    const lightest = '#e6f7ff';
    const darkest = '#002766';
  
    const r1 = parseInt(lightest.slice(1, 3), 16);
    const g1 = parseInt(lightest.slice(3, 5), 16);
    const b1 = parseInt(lightest.slice(5, 7), 16);
  
    const r2 = parseInt(darkest.slice(1, 3), 16);
    const g2 = parseInt(darkest.slice(3, 5), 16);
    const b2 = parseInt(darkest.slice(5, 7), 16);
  
    const index = Math.floor((value / 100) * 15);
  
    const r = Math.round(r1 + (r2 - r1) * (index / 15)).toString(16).padStart(2, '0');
    const g = Math.round(g1 + (g2 - g1) * (index / 15)).toString(16).padStart(2, '0');
    const b = Math.round(b1 + (b2 - b1) * (index / 15)).toString(16).padStart(2, '0');
    const final = value === null ? `#ffffff` : `#${r}${g}${b}`
    return final;
  };

const StyledTable = styled(Table)`
    && {
        .ant-table-tbody>tr>td {
        padding: 2px;
    }
}
`;


export const CohortRetentionChart = () => {
  const [cohortData, setCohortData] = useState([]);
  const [userAudiences, setUserAudiences] = useState([]);
  const [columns, setColumns] = useState([
        {
            title: 'Cohort',
            dataIndex: 'cohort',
            key: 'cohort',
            fixed: 'left',
            width: 200
        },
    ]
  );
  const [limit, setLimit] = useState(1000);
  const { location, push } = useHistory();
  const { basePath, baseUrl } = playgroundContext;
  const apiUrl = buildApiUrl(baseUrl, basePath);
  const { decodeCheckExpiredToken, getUserDepartment, getUserName, currentToken } = useSecurityContext();
  const department = getUserDepartment();
  const userName = getUserName();
  const [queryToRun, setQueryToRun] = useState([]);
  const GASchema = 'GA4';
  const timeDimension = {
    dimension: {
        name: "Events.date",
        title: "GA4 Events Traffic Date",
        shortTitle: "Traffic Date",
    },
    dateRange: [moment().subtract(14, "days").format("YYYY-MM-DD"), moment().subtract(1, "days").format("YYYY-MM-DD")],
    granularity: "day",
    index: 0
}
  const params = new URLSearchParams(location.search);
  let query = {};
  try{ 
    query = JSON.parse(params.get('query'));
  } catch(e) { 
    console.log('Bad query passed!')
  }

  const [metaExtended, setMetaExtended] = useState([]);
  const renderProps = useCubeQuery(queryToRun, {skip: queryToRun.length === 0});
  
  function setQueryParam({ query }) {
    if (!('timeDimensions' in query)) {
        query = {timeDimensions: [{dimension: timeDimension.dimension.name, granularity: timeDimension.granularity, dateRange: timeDimension.dateRange}]};
    }
        push({ search: `?query=${encodeURIComponent(JSON.stringify(query))}` });
  }

  const getAudiencesFromDb = () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
    };
    fetch(`${backendApiUrl}/audiences?sub=${jwtDecode(currentToken).sub}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setUserAudiences(data)
        });
  }


  useEffect(() => {
    getAudiencesFromDb()
  }, [])

  useEffect(() => {
    if('resultSet' in renderProps && renderProps.resultSet){
        const data = renderProps.resultSet.loadResponse.results.map(res => {
            const initialCube = Object.keys(res.data[0])[0].split('.')[0]
            const cohort = initialCube.split(`${userName.replace('.', '_')}_`)[1].replace(/_/g, ' ')
            return {
                cohort: cohort,
                cohort_size: res.data.map(d => d[`${initialCube}.total_count`])[0],
                ...res.data.map(d => ({percentage: d[`${initialCube}.percentage_of_active`], value: d[`${initialCube}.total_active_count`]}))
            }
        })
        setCohortData(data)
    }
  }, [renderProps.resultSet, renderProps.isLoading])

  const playgroundActionUpdateMethods = (updateMethods, memberName) =>
  Object.keys(updateMethods)
    .map((method) => ({
      [method]: (member, values, ...rest) => {
        return updateMethods[method].apply(null, [member, values, ...rest]);
      },
    }))
    .reduce((a, b) => ({ ...a, ...b }), {});


    const handleRunButtonClick = ({query}) => {
        const multiQuery = query.segments.map(seg => {
            const cohortSeg = seg.split('.')[1];
            const timeGrain = capitalize(query.timeDimensions[0].granularity);
            const retentionCube = `Retention${timeGrain}__${cohortSeg}`
            return {
                timeDimensions: [{...query.timeDimensions[0], dimension: `${retentionCube}.date`}], 
                measures: [`${retentionCube}.percentage_of_active`, `${retentionCube}.total_count`, `${retentionCube}.total_active_count`],
                limit: limit
            }
        })
        setQueryToRun(multiQuery)
        setLimit(limit === 1000 ? 1001 : 1000)
    }

  return (
    ['CEO Office', 'CEO office', 'Consumer Subscription, Audience & Data'].includes(jwtDecode(currentToken).user_department) ? <QueryContext apiUrl={apiUrl} metaExtended={metaExtended} department={department}>
        <QueryBuilder
            defaultQuery={query || {timeDimensions: [{dimension: timeDimension.dimension.name, granularity: timeDimension.granularity, dateRange: timeDimension.dateRange}]}}
            wrapWithQueryRenderer={false}
            onVizStateChanged={(vizState) => {
                setQueryParam({query: vizState.query});
                // setColumns
                const dateRange = vizState.query.timeDimensions[0].dateRange;
                const timeGrain = vizState.query.timeDimensions[0].granularity;
                const localColumns = [
                    {
                        title: 'Cohort',
                        dataIndex: 'cohort',
                        key: 'cohort',
                        fixed: 'left',
                        width: 200,
                        render: (value, record) => 
                            <div>
                            {record['cohort']}<br/>
                            <div className='ant-form-item-explain'><b>{record['cohort_size'].toLocaleString('en-US')}</b> users</div>
                            </div>
                        ,
                    },
                ];
                for (let i = 0; i < moment(dateRange[1]).diff(moment(dateRange[0]), timeGrain)+1; i++) {
                    localColumns.push({
                        title: `${capitalize(timeGrain)} ${i}`,
                        dataIndex: `${i}`,
                        key: `${i}`,
                        className: 'cell-background',
                        width: 100,
                        render: (value) => (
                            <Tooltip placement="top" title={value.value.toLocaleString('en-US')}>
                                <StyledCell value={value.percentage}>
                                {value.percentage.toFixed(2)}{value.percentage === null ? '-' : '%'}
                                </StyledCell>
                            </Tooltip>
                        ),
                    });
                }
                setCohortData([])
                setColumns(localColumns);
            }}
            render={({
                query,
                segments,
                updateSegments,
                timeDimensions,
                updateTimeDimensions,
                missingMembers,
                updateQuery,
                isFetchingMeta,
                availableMembers,
                dryRunError,
            }) => {
                availableMembers.timeDimensions = filterMembers(GASchema, 'retention', department, userName, availableMembers.timeDimensions, timeDimensions, metaExtended, query, 'time')
                availableMembers.segments = filterMembers(GASchema, 'retention', department, userName, availableMembers.segments, segments, metaExtended, query, 'segment')
                // console.log(query)
                return (
                    <Container>
                            <Row align='middle' justify="space-between" style={{width: '100%'}}>
                                <Col span={24} type="flex">
                                    <StyledCard>
                                        <Section>
                                            <SectionHeader>Audiences (5 max)</SectionHeader>
                                            <MemberGroup
                                                disabled={isFetchingMeta}
                                                members={segments}
                                                availableMembers={availableMembers?.segments || []}
                                                missingMembers={missingMembers}
                                                addMemberName="Audiences"
                                                updateMethods={playgroundActionUpdateMethods(
                                                    updateSegments,
                                                    'Segment'
                                                )}
                                            />
                                            {userAudiences.length === 0 && <p className='ant-form-item-explain'>It looks like you don't have audiences define yet. Get started by clicking <Link target='_blank' rel='noopener noreferrer' to="/audience">here</Link></p>}
                                        </Section>

                                        <Section>
                                            <SectionHeader>Time</SectionHeader>
                                            <TimeGroup
                                                mode="retention"
                                                disabled={isFetchingMeta}
                                                members={timeDimensions}
                                                availableMembers={availableMembers?.timeDimensions || []}
                                                missingMembers={missingMembers}
                                                addMemberName="Time"
                                                makeTimeGroupUnclosable={true}
                                                updateMethods={playgroundActionUpdateMethods(
                                                    updateTimeDimensions,
                                                    'Time'
                                                )}
                                            />
                                        </Section>
                                        <Row style={{ paddingTop: '24px' }} justify='center'>   
                                        
                                            
                                            <Button
                                                icon={<PlaySquareOutlined />}
                                                type="primary"
                                                className="main-button"
                                                loading={renderProps.isLoading}
                                                disabled={segments.length === 0 || isFetchingMeta || dryRunError}
                                                onClick={async () => {
                                                    await decodeCheckExpiredToken();
                                                    handleRunButtonClick({query});
                                                }}
                                            >
                                                Run query
                                            </Button>
                                        </Row>
                                    </StyledCard>
                                </Col>
                            </Row>
                        
                        <Row align='middle' justify="space-between" style={{width: '100%'}}>
                            <Col span={24} type="flex" style={{marginTop: 30}}>
                                <Space direction='vertical' size='large' style={{display: 'flex', justifyContent: "space-between"}}>
                                    <StyledTable
                                        dataSource={cohortData}
                                        columns={columns}
                                        pagination={false}
                                        rowKey={(record) => record.cohort}
                                        scroll={{
                                            x: columns.length > 10 ? 1500 : columns.length > 5 ? 1000 : 500,
                                            y: 1200,
                                        }}
                                        loading={renderProps.isLoading}
                                    />
                                </Space>
                            </Col>
                        </Row>
                    </Container>
                )
            }}
        />
    </QueryContext> : <Container>
                        
                        <Row align='middle' justify="space-between" style={{width: '100%'}}>
                            <Col span={24} type="flex">
                                <StyledTitle style={{marginBottom: "16px"}}>Cohorts Retention</StyledTitle>
                                    
                                    <Alert 
                                        message="Access Denied"
                                        description="Contact jonathan.barone@scmp.com on slack in #exodus channel or in DM if you wish to get access."
                                        type="error" 
                                        showIcon 
                                    />
                                  
                            </Col>
                        </Row>
                    </Container>
  );
};

export default CohortRetentionChart;