import { useMemo } from 'react';

import { useDeepDependencies } from './deep-dependencies';

function useDeepMemo(
  callback,
  dependencies
) {
  const memoizedDependencies = useDeepDependencies(dependencies);
  return useMemo(callback, memoizedDependencies);
}

export default useDeepMemo;