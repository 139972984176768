import { Component } from 'react';
import Prism from 'prismjs';


export default class PrismCode extends Component {
  componentDidMount() {
    Prism.highlightAll();
  }

  componentDidUpdate() {
    Prism.highlightAll();
  }

  render() {
    return (
      <pre style={this.props.style}>
        <code
          data-testid="prism-code"
          className={`language-${this.props.language || 'javascript'}`}
        >
          {this.props.code}
        </code>
      </pre>
    );
  }
}