import React from 'react';
import { Tooltip,  Button } from 'antd';
import { useCSVDownloader } from 'react-papaparse';
import  { DownloadOutlined } from '@ant-design/icons';

export default function CSVExport({data, disabled}) {
  const { CSVDownloader, Type } = useCSVDownloader();

  return (
    <CSVDownloader
      type={Type.Link}
      filename={'Exodus'}
      config={{
        delimiter: ',',
      }}
      data={data}
    >
      <Tooltip title="Download CSV">
        <Button type="secondary" shape="round" icon={<DownloadOutlined />} disabled={data.length === 0 || disabled} />
      </Tooltip>
    </CSVDownloader>
  );
}