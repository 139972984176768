
import { Segmented } from 'antd';
import {
  AreaChartOutlined,
  TableOutlined,
} from '@ant-design/icons';


const SelectChartType = ({ chartType, chartOption, updateChartOption }) => {

  return (
    chartType === 'table' || chartType === 'number' ? <Segmented
    options={[
      {
        label: 'Table',
        value: 'table',
        icon: <TableOutlined />,
      },
    ]}
    value={chartOption}
    onChange={updateChartOption}
  /> : <Segmented
    options={[
      {
        label: 'Chart',
        value: 'chart',
        icon: <AreaChartOutlined />,
      },
      {
        label: 'Table',
        value: 'table',
        icon: <TableOutlined />,
      },
    ]}
    value={chartOption}
    onChange={updateChartOption}
  />
  );
};

export default SelectChartType;