import {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSecurityContext } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { globallyAllowedGA3Cubes, globallyAllowedGA4Cubes, globallyAllowedGA3Reporting, globallyAllowedGA4Reporting, globallyAllowedOtherCubes } from '../../config';

export const Context = createContext({});

export function MetaContext({
  apiUrl,
  cubejsToken,
  children,
  ...props
}) {
  const [error, setError] = useState(null);
  const [meta, setMeta] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCubeJsMeta = async (apiUrl, cubejsToken) => {
    setIsLoading(true);
    const globallyAllowedCubes = globallyAllowedGA3Cubes.concat(globallyAllowedGA4Cubes).concat(globallyAllowedOtherCubes)
                                                         .concat(globallyAllowedGA3Reporting).concat(globallyAllowedGA4Reporting);
    // query
    await fetch(`${apiUrl}/meta`,{
      headers: {
        Authorization: `${cubejsToken}`,
      },
    }).then(data => {
      return data.json()
    }).then(json => {
      const filteredCubes = json['cubes'].filter(cube => globallyAllowedCubes.includes(cube.name))
      setMeta(filteredCubes)
    })
  }

  let { isLoggedIn, decodeCheckExpiredToken } = useSecurityContext();
  const { push } = useHistory();

  useEffect(() => {
    if (isLoggedIn){
      console.log('checking token expiration...')
      decodeCheckExpiredToken();
      fetchCubeJsMeta(apiUrl, cubejsToken);
    } else {
      push('/login');
    }
  }, [isLoggedIn]);

  return (
    <Context.Provider
      value={{
        isLoading,
        meta,
        error,
        fetchCubeJsMeta
      }}
    >
      {children}

    </Context.Provider>
  );
}

export function useMetaContext() {
  return useContext(Context);
}