import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import { useHistory } from 'react-router-dom';
import { Container } from '../../atoms/Container';
import { useSecurityContext } from '../../hooks';
import { useQueryContext, QueryContext } from '../QueryContext';
import { useFilterValuesContext, FilterValuesContext } from '../FilterValuesContext';
import { useMetaContext } from '../MetaContext';
import { QueryTabs } from '../QueryTabs/QueryTabs';
import ExploreQueryBuilder from './components/ExploreQueryBuilder';
import AudienceQueryBuilder from './components/AudienceQueryBuilder';
import { playgroundContext, buildApiUrl, frontendifyQuery } from '../../config';


export function QueryBuilderContainer(props) {
  const { basePath, baseUrl } = playgroundContext;
  const apiUrl = buildApiUrl(baseUrl, basePath);
  const {
    token: securityContextToken,
    getUserDepartment
  } = useSecurityContext();


  const { meta, isLoading } = useMetaContext();
  const department = getUserDepartment();


  return (
      <FilterValuesContext apiUrl={apiUrl} cubejsToken={securityContextToken}>
          <QueryContext apiUrl={apiUrl} metaExtended={meta} department={department}>
              <Container bordered={false}>
                  <QueryTabsRenderer
                    apiUrl={apiUrl}
                    token={securityContextToken}
                    metaExtended={meta}
                    onTabChange={props.onTabChange}
                    onVizStateChanged={props.onVizStateChanged}
                    mode={props.mode}
                  />
              </Container>
          </QueryContext>
      </FilterValuesContext>
  );
}

function QueryTabsRenderer({
  apiUrl,
  token,
  metaExtended,
  ...props
}) {
  const { location } = useHistory();
  const { setQuery, error, setGASchema, setMode, setRenderProps, setChartOption, setChartToDisplay } = useQueryContext();
  const { filterValues, fetchCubeJsPreAgg } = useFilterValuesContext();
  const params = new URLSearchParams(location.search);
  let query = {};
  try{ 
    query = JSON.parse(params.get('query') || '{}');
    query = frontendifyQuery(query)
  } catch(e) { 
    console.log('Bad query passed!')
  }

  return (
    <QueryTabs
      query={query}
      sidebar={
        <Space direction="horizontal">
        </Space>
      }
      tabMode={props.mode}
      onTabChange={(tab) => {
        props.onTabChange?.(tab);
        setQuery(tab.query);
        setGASchema(tab.GASchema);
        setMode(tab.mode);
        setRenderProps(tab.renderProps)
        setChartOption(tab.chartOption)
        setChartToDisplay(tab.chartToDisplay)
      }}
    >
      {({ id, tabName, query, chartType, GASchema, mode, renderProps, chartOption, chartToDisplay, queryId }, saveTab) => (
        props.mode === 'query' ? <ExploreQueryBuilder
          queryId={id}
          apiUrl={apiUrl}
          query={query}
          cubejsToken={token}
          dryRunError={error}
          renderProps={renderProps}
          chartToDisplay={chartToDisplay}
          chartOption={chartOption}
          setChartOption={setChartOption}
          metaExtended={metaExtended}
          GASchema={GASchema}
          setGASchema={setGASchema}
          mode={mode}
          initialVizState={{
            query,
            chartType,
          }}
          onVizStateChanged={(vizState) => {
            saveTab({
              query: vizState.query || {},
              tabName: tabName,
              GASchema: GASchema,
              mode: mode,
              chartType: vizState.chartType,
              renderProps: renderProps,
              chartOption: chartOption,
              chartToDisplay: chartToDisplay,
              queryId: queryId,
            });
            props.onVizStateChanged?.({query: vizState.query});

            if (vizState.query) {
              setQuery(vizState.query);
              setRenderProps(renderProps);
              if ('filters' in vizState.query && vizState.query.filters.length > 0){
                vizState.query.filters.forEach(filter => fetchCubeJsPreAgg(apiUrl, token, filter.member))
              }
            }
          }}
        /> :
        <AudienceQueryBuilder
          queryId={id}
          apiUrl={apiUrl}
          query={query}
          cubejsToken={token}
          dryRunError={error}
          renderProps={renderProps}
          chartToDisplay={chartToDisplay}
          chartOption={chartOption}
          setChartOption={setChartOption}
          metaExtended={metaExtended}
          GASchema={GASchema}
          setGASchema={setGASchema}
          mode={props.mode}
          initialVizState={{
            query,
            chartType,
          }}
          onVizStateChanged={(vizState) => {
            saveTab({
              query: vizState.query || {},
              tabName: tabName,
              GASchema: GASchema,
              mode: props.mode,
              chartType: vizState.chartType,
              renderProps: renderProps,
              chartOption: chartOption,
              chartToDisplay: chartToDisplay,
              queryId: queryId,
            });
            props.onVizStateChanged?.({query: vizState.query});

            if (vizState.query) {
              setQuery(vizState.query);
              setRenderProps(renderProps);
              if ('filters' in vizState.query && vizState.query.filters.length > 0){
                vizState.query.filters.forEach(filter => fetchCubeJsPreAgg(apiUrl, token, filter.member))
              }
            }
          }}
        />
      )}
    </QueryTabs>
  );
}