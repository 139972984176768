import {SCMP_PALE_COLORS_SERIES, SCMP_COLORS_SERIES} from '../../ChartContainer/chartConfigs';


export const getDailyPageviewsChartData = (dataChart, labels) => {
    return {
      labels: labels,
      datasets: [
        {
          label: 'Pageviews',
          data: dataChart,
          borderColor: SCMP_COLORS_SERIES[1],
          backgroundColor: SCMP_PALE_COLORS_SERIES[1],
          yValues: ['Pageviews'],
          fill: true,
        },
      ],
    }
  }


  export const getPlatformChartData = (dataChart, labels) => {
    return {
      labels: labels,
      datasets: [
        {
          label: 'Users',
          data: dataChart,
          borderColor: SCMP_COLORS_SERIES,
          backgroundColor: SCMP_PALE_COLORS_SERIES,
          yValues: ['Users'],
          fill: false,
        },
      ],
    }
  }

  export const getSourceChartData = (dataChart, labels) => {
    return {
      labels: labels,
      datasets: [
        {
          label: 'Users',
          data: dataChart,
          borderColor: SCMP_COLORS_SERIES[0],
          backgroundColor: SCMP_PALE_COLORS_SERIES[0],
          yValues: ['Users'],
          fill: false,
        },
      ],
    }
  }

  export const getCountryChartData = (dataChart, labels) => {
    return {
      labels: labels,
      datasets: [
        {
          label: 'Users',
          data: dataChart,
          borderColor: SCMP_COLORS_SERIES[0],
          backgroundColor: SCMP_PALE_COLORS_SERIES[0],
          yValues: ['Users'],
          fill: false,
        },
      ],
    }
  }