import {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSecurityContext } from '../../hooks';
import { useHistory } from 'react-router-dom';

export const Context = createContext({});

export function FilterValuesContext({
  apiUrl,
  cubejsToken,
  children,
  ...props
}) {
  const [error, setError] = useState(null);
  const [filterValues, setFilterValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);


  const fetchCubeJsPreAgg = async (apiUrl, cubejsToken, filterMember) => {
    if (!(filterMember in filterValues)){
      const dimension = [`GA`, `UAAppleUnion`].includes(filterMember.split('.')[0]) ? `GAPreAggs.${filterMember.split('.')[1]}` 
                      : [`Drupal`, `DrupalGA4`, `DrupalGA4Apple`, `DrupalUAApple`].includes(filterMember.split('.')[0]) ? `DrupalPreAggs.${filterMember.split('.')[1]}` 
                      : filterMember.split('.')[0] === 'Loyalty' ? `LoyaltyPreAggs.${filterMember.split('.')[1]}` 
                      : filterMember.split('.')[0] === 'UsersAttributes' ? `UsersAttributesPreAggs.${filterMember.split('.')[1]}`
                      : filterMember.split('.')[0] === 'Readership' ? `ReadershipPreAggs.${filterMember.split('.')[1]}`
                      : filterMember.split('.')[0] === 'RegisteredUsers' ? `RegisteredUsersPreAggs.${filterMember.split('.')[1]}` 
                      : filterMember.split('.')[0] === 'Subscriptions' ? `SubscriptionsPreAggs.${filterMember.split('.')[1]}` 
                      : filterMember.split('.')[0] === 'CampaignsUnion' ? `CampaignsUnionPreAggs.${filterMember.split('.')[1]}` 
                      : [`Events`, `GA4AppleUnion`].includes(filterMember.split('.')[0]) ? `EventsPreAggs.${filterMember.split('.')[1]}`
                      : filterMember;
      setIsLoading(true)
      fetch(`${apiUrl}/load`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cubejsToken}`,
        },
        body: JSON.stringify({
          query: {
            dimensions: [dimension]
          },
        })
      }).then(data => {
        return data.json()
      }).then(json => {
        filterValues[filterMember] = [`GA4AppleUnion`, `UAAppleUnion`].includes(filterMember.split('.')[0]) && ['platform', 'traffic_source', 'traffic_type', 'source_medium'].includes(filterMember.split('.')[1]) ? 
                                                                                                              json['data'].map(e => Object.assign({value: e[dimension], label: e[dimension]})).filter(e => e.value && e.value !== '').concat({value: 'Apple News', label: 'Apple News'})
                                                                                                            : json['data'].map(e => Object.assign({value: e[dimension], label: e[dimension]})).filter(e => e.value && e.value !== '')
        setFilterValues({ ...filterValues })
      }).then(data => setIsLoading(false));
    }
  }

  let { isLoggedIn, decodeCheckExpiredToken} = useSecurityContext();
  const { push } = useHistory();

  useEffect(() => {
    if (isLoggedIn){
      console.log('checking token expiration...')
      decodeCheckExpiredToken();
    } else {
      push('/login');
    }
  }, [isLoggedIn]);

  return (
    <Context.Provider
      value={{
        isLoading,
        filterValues,
        error,
        fetchCubeJsPreAgg
      }}
    >
      {children}

    </Context.Provider>
  );
}

export function useFilterValuesContext() {
  return useContext(Context);
}