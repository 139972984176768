import { createContext, useState, useEffect, memo } from 'react';
import jwtDecode from 'jwt-decode';
import { useLocalStorage } from '../../hooks';


export const SecurityContextContext = createContext({
  payload: '',
  token: null,
  currentToken: null,
});


let refreshingToken = null;

export const SecurityContextProvider = memo(function SecurityContextProvider({
  children,
  tokenUpdater,
  onTokenPayloadChange,
}) {
  const [savedCubeToken, setCubeToken, removeCubeToken] = useLocalStorage('cubejsToken', null);
  const [savedGoogleFullRes, setGoogleFullRes, removeGoogleFullRes] = useLocalStorage('googleFullResponse', null);
  const [savedGoogleRefreshRes, setGoogleRefreshRes, removeGoogleRefreshRes] = useLocalStorage('googleRefreshResponse', null);

  const [payload, setPayload] = useState('');
  const isLoggedIn = savedCubeToken && savedGoogleFullRes ? true: false;
  const stepLoginFlow = savedCubeToken ? 2 : savedGoogleFullRes ? 1 : 0;

  async function refreshCubeToken(removesavedCubeToken = false) {
    if (
      savedCubeToken != null &&
      tokenUpdater &&
      refreshingToken !== savedCubeToken
    ) {
        refreshingToken = savedCubeToken;
        const refreshedToken = await tokenUpdater(savedCubeToken);
        if (savedCubeToken && removesavedCubeToken === false) {
            setCubeToken(refreshedToken);
        }
        refreshingToken = null;
    }
  }

  const refreshGoogleResSetup = (res) => {
    // Timing to renew access token
    if(res && !savedGoogleRefreshRes){
        setGoogleRefreshRes(res.tokenObj)
        console.log('Setting up refresh in progress...')
        let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;
        const refreshRes = async (res) => {
            if('accessToken' in res && res.accessToken !== null){
                const newAuthRes = await res.reloadAuthResponse();
                refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
                console.log('refreshed Google Response!');
                setGoogleRefreshRes(newAuthRes);
                // Setup the other timer after the first one
                setTimeout(() => refreshRes(res), refreshTiming);
            }
        };
        // Setup first refresh timer
        setTimeout(() => refreshRes(res), refreshTiming);
    }
  };

  const decodeCheckExpiredToken = async (hardDeleteTokens=false) => {
    const currentTimestamp = Date.now()
    // check Google Token
    if(savedGoogleRefreshRes && savedGoogleRefreshRes.expires_at + 60 <= currentTimestamp){
        removeGoogleRefreshRes();
    }
    // check Cube Token
    const payload = jwtDecode(savedCubeToken);
    
    if (isLoggedIn && payload.exp*1000 + 120 <= currentTimestamp){
        removeGoogleFullRes();
        removeGoogleRefreshRes();
        removeCubeToken();
    }
    // hard delete
    if (hardDeleteTokens){
        removeGoogleFullRes();
        removeGoogleRefreshRes();
        removeCubeToken();
    }
    return payload
  }

  const getUserDepartment = () => {
    // check Cube Token
    const payload = jwtDecode(savedCubeToken);
    return payload.user_department
  }

  const getUserName = () => {
    // check Cube Token
    const payload = jwtDecode(savedCubeToken);
    return payload.user_email.split('@')[0]
  }

  useEffect(() => {
    if (savedCubeToken) {
      try {
        const payload = decodeCheckExpiredToken()
        setPayload(JSON.stringify(payload, null, 2));
      } catch (error) {
        setPayload('');
        console.log(savedCubeToken)
        console.error('Invalid JWT token', savedCubeToken);
      }
    } else {
      setPayload('');
    }
  }, [savedCubeToken]);

  return (
    <SecurityContextContext.Provider
      value={{
        token: savedCubeToken,
        isLoggedIn: isLoggedIn,
        savedGoogleFullRes,
        currentToken: savedCubeToken,
        payload,
        async saveToken(token) {
          if (!token) {
            await refreshCubeToken(true);
            removeCubeToken();
          } else {
            setCubeToken(token);
          }
        },
        refreshCubeToken,
        onTokenPayloadChange,
        decodeCheckExpiredToken,
        getUserDepartment,
        getUserName,
        refreshGoogleResSetup,
        removeCubeToken,
        setGoogleRefreshRes,
        removeGoogleRefreshRes,
        setGoogleFullRes,
        removeGoogleFullRes,
        stepLoginFlow
      }}
    >
      {children}
    </SecurityContextContext.Provider>
  );
});