import Icon from '@ant-design/icons';


export default function CubejsIcon() {
  return (
    <Icon
      component={() => (
          <svg
            width="28px"
            height="28px"
            viewBox="0 0 896 1024"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path
                d="M895.492 257.384L447.827 0V178.087L895.492 434.365V257.384Z"
                fill="#3F88FE"
              />
              <path
                d="M895.485 434.372L761.186 512.007L447.74 332.115L268.64 434.515L134.315 362.558L447.823 178.094L895.485 434.372Z"
                fill="#001246"
              />
              <path
                d="M268.64 434.504L134.315 362.547V511.995L268.64 434.504Z"
                fill="#003296"
              />
              <path
                d="M0 766.618L447.749 512L895.498 766.618L447.749 1024L0 766.618Z"
                fill="#001246"
              />
              <path
                d="M895.498 589.916L447.749 329.344V512.003L895.498 766.618V589.916Z"
                fill="#3F88FE"
              />
              <path
                d="M134.325 512V362.552L447.833 178.087V0L0 257.384V766.618L447.749 512V329.341L134.325 512Z"
                fill="#004BBB"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="895.498" height="1024" fill="none" />
              </clipPath>
            </defs>
          </svg>
      )}
    />
  );
}