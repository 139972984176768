import React, { useEffect, useState } from 'react';
import { Modal, List, Skeleton, Input, Space} from 'antd';
import { backendApiUrl } from '../../config';
import { useSecurityContext } from '../../hooks';
import styled from 'styled-components';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

const { Search } = Input;

const Link = styled.a`
&& {
    color: rgba(0,0,0,.45);
    &:hover {
        opacity: 0.8;
      }s
}
`;


export function AddNewDataSourceModal({ isModalOpen, setIsModalOpen, getDashboardFromDb, dashboard }) {

  const {currentToken } = useSecurityContext();
  const [queryTables, setQueryTables] = useState([]);
  const [searchedQuery, setSearchedQuery] = useState('');

  const getQueriesFromDb = () => {
    const payload = jwtDecode(currentToken);
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
    };
    fetch(`${backendApiUrl}/queries?prodTables=true`, requestOptions)
        .then(response => response.json())
        .then(data => {
          setQueryTables(data)
        });
  }

  useEffect(() => {
    getQueriesFromDb();
  }, [])
  
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const makeDisplayQuery = (query) => {
    return (
        <ul style={{ width: '300px'}}>
        <li style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
            metrics: {query.measures ? query.measures.toString() : "n/a"}
        </li>
        <li style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
            dimensions: {query.dimensions ? query.dimensions.toString(): "n/a"}
        </li>
    </ul>)
  }

  const handleClickOpen = (item) => {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
      body: JSON.stringify({
          title: dashboard.title,
          url: dashboard.url,
          type: 'Looker Studio',
          status: dashboard.status,
          start_date: moment(dashboard.start_date).utc().format('YYYY-MM-DDTHH:mm:ss'),
          queries: 'queries' in dashboard ? dashboard.queries.map(query => query.id).concat(item.id) : [item.id],
      })
    }
    fetch(`${backendApiUrl}/dashboard/${dashboard.id}`, requestOptions)
      .then(response => response.json())
      .then(data => {
          getDashboardFromDb();
      })
    setIsModalOpen(false);
  }

  return (
    <Modal title="Add Table" open={isModalOpen} footer={null} onCancel={handleCancel} width={800}>
        <Search
            placeholder="input search text"
            onSearch={(value) => setSearchedQuery(value)}
            onChange={(e) => setSearchedQuery(e.target.value)}
            style={{
                width: 200,
                marginBottom: 10
            }}
        />
        <List 
            dataSource={queryTables.filter(query => !dashboard.queries?.map(query => query.id).includes(query.id) && query.bq_table_name.toLowerCase().includes(searchedQuery.toLowerCase()))}
            pagination={{
                total: queryTables.length,
                pageSize: 5,
                showTotal: (total) => { return `Total ${total} tables`}
            }}
            renderItem={(item) => 
                <List.Item 
                    actions={
                      [
                        <a onClick={() => handleClickOpen(item)} style={{fontWeight: 'bold'}}>add</a>,
                        <Link target='_blank' rel='noopener noreferrer' style={{textDecoration: 'underline'}}href={`https://exodus-cube.scmp.com/app/?query=${item.query_obj}`}>open query in explore</Link>
                    ]}
                >
                    <Skeleton avatar title={false} loading={item.loading} active>
                        <List.Item.Meta
                            avatar={<img src={`${process.env.PUBLIC_URL}/logo_bigquery.jpg`} alt="bigquery logo" width='30'/>}
                            title=<Link style={{color: '#1890ff', fontWeight: 'bold'}} onClick={() => handleClickOpen(item)}>{`cube_reporting.` + item.bq_table_name}</Link>
                            description={makeDisplayQuery(JSON.parse(item.query_obj))}
                        />
                        <div>{item.ga_schema}</div>
                    </Skeleton>
                </List.Item>
            }
        />
    </Modal>
  );
}