import { PlusOutlined } from '@ant-design/icons';
import { useCallback } from 'react';

import MemberDropdown from './MemberDropdown';
import RemoveButtonGroup from './RemoveButtonGroup';
import SectionRow from '../SectionRow';
import MissingMemberTooltip from './MissingMemberTooltip';
import { globallyAllowedGA3Cubes, globallyAllowedGA4Cubes, globallyAllowedGA3Reporting, globallyAllowedGA4Reporting, globallyAllowedOtherCubes } from '../../config';



export default function MemberGroup({
  disabled = false,
  members,
  availableMembers,
  missingMembers,
  addMemberName,
  updateMethods,
}) {
  const handleClick = useCallback((m) => updateMethods.add(m), []);
  const globallyAllowedCubes = globallyAllowedGA3Cubes.concat(globallyAllowedGA4Cubes).concat(globallyAllowedOtherCubes)
                                                      .concat(globallyAllowedGA3Reporting).concat(globallyAllowedGA4Reporting);
  return (
    <SectionRow>
      {members.map((m) => {
        const isMissing = missingMembers.includes(m.title);

        const buttonGroup = (
          <RemoveButtonGroup
            key={m.index || m.name}
            disabled={disabled}
            className={disabled ? 'disabled' : null}
            color={isMissing ? 'danger' : 'primary'}
            onRemoveClick={() => updateMethods.remove(m)}
            enableClose={addMemberName !== "User ID" ? true: false}
          >
            <MemberDropdown
              disabled={disabled}
              availableCubes={availableMembers.filter(mem => globallyAllowedCubes.includes(mem.cubeName))}
              addMemberName={addMemberName}
              onClick={(updateWith) => updateMethods.update(m, updateWith)}
            >
              {m.shortTitle}
            </MemberDropdown>
          </RemoveButtonGroup>
        );

        return isMissing ? (
          <MissingMemberTooltip key={m.index || m.name}>
            {buttonGroup}
          </MissingMemberTooltip>
        ) : (
          buttonGroup
        );
      })}

      {(addMemberName !== 'User ID' || disabled) && <MemberDropdown
        data-testid={addMemberName}
        disabled={disabled}
        availableCubes={availableMembers.filter(mem => globallyAllowedCubes.includes(mem.cubeName))}
        type="dashed"
        icon={<PlusOutlined />}
        onClick={handleClick}
      >
        {!members.length ? addMemberName : null}
      </MemberDropdown>}
    </SectionRow>
  );
}