import React, { useEffect, useState } from 'react';
import { Modal, List, Skeleton, notification, Input, Segmented, Space, Form, Button } from 'antd';
import { backendApiUrl } from '../../config';
import { useSecurityContext } from '../../hooks';
import { SaveTwoTone } from '@ant-design/icons';
import styled from 'styled-components';
import jwtDecode from 'jwt-decode';

const { Search } = Input;

const Link = styled.a`
&& {
    color: rgba(0,0,0,.45);
    &:hover {
        opacity: 0.8;
      }s
}
`;


export function AddNewDashboardModal({ isModalOpen, setIsModalOpen, getDashboardsFromDb, dashboardsFromDb }) {

  const {currentToken, getUserDepartment } = useSecurityContext();
  const [dashboards, setDashboards] = useState([]);
  const [searchedQuery, setSearchedQuery] = useState('');
  const [method, setMethod] = useState('datastudio');
  const [inputName, setInputNameValue] = useState('datastudio');
  const [inputURL, setInputURLValue] = useState('datastudio');
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const getLookerStudioDashboards = () => {
    setIsLoading(true)
    const payload = jwtDecode(currentToken);
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
    };
    fetch(`${backendApiUrl}/datastudio/reports`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setIsLoading(false)
            setDashboards(data)
        });
  }

  useEffect(() => {
    getLookerStudioDashboards();
  }, [])
  
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleClickOpen = (item) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
        body: JSON.stringify({
            title: item.title,
            url: item.url,
            type: item.url.includes('lookerstudio.google.com')  ? 'Looker Studio' : 
                item.url.includes('docs.google.com') ? 'Google Sheets' : 
                item.url.includes('analytics.scmp.com') ? 'Tableau' : 
                item.url.includes('exodus-cube.scmp.com') ? 'Exodus' : 'Other',
        })
      }
      fetch(`${backendApiUrl}/dashboards`, requestOptions)
        .then(response => response.json())
        .then(data => {
          const message = `Dashboard ${item.title} added!`
          notification.open({
            message: message,
            icon: <SaveTwoTone />,
            duration: 10,
            style: {width: `${Math.max(84, message.length * 10)}px`}
          })
        })
        .then(data => {
            getDashboardsFromDb();
        })
    setIsModalOpen(false);
  }

  const handleFormSubmit = async (values) => {
    try {
        const values = await form.validateFields();
    } catch (errorInfo) {
        return;
    }
    handleClickOpen({title: inputName, url: inputURL})
  }

  const handleInputNameChange = (e) => {
    setInputNameValue(e.target.value);
  }

  const handleInputURLChange = (e) => {
    setInputURLValue(e.target.value);
  }

  return (
    <Modal 
        title="Add a report or dashboard" 
        open={isModalOpen} 
        footer={method === 'datastudio' ? null : [<Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleFormSubmit}>
            Add
          </Button>]} 
        okText='Add' 
        onOk={form.submit} 
        onCancel={handleCancel} 
        width={800}
    >
        <Space direction='vertical' size={'large'}>
            <Segmented
            options={[
                {
                    label: 'Looker Studio',
                    value: 'datastudio',
                },
                {
                    label: 'From URL',
                    value: 'url',
                },
            ]}
            value={method}
            onChange={setMethod}
            />
            {method === 'datastudio' && <Search
                placeholder="input search text"
                onSearch={(value) => setSearchedQuery(value)}
                onChange={(e) => setSearchedQuery(e.target.value)}
                style={{
                    width: 200,
                    marginBottom: 10
                }}
            />}
        </Space>
        {method === 'datastudio' ? <List 
            dataSource={dashboards.filter(
                dashboard => !dashboardsFromDb.map(dash => dash.url).includes(`https://lookerstudio.google.com/reporting/${dashboard.name}`) && dashboard.title.toLowerCase().includes(searchedQuery.toLowerCase())
            )}
            loading={isLoading}
            pagination={{
                total: dashboards.length,
                pageSize: 5,
                showTotal: (total) => { return `Total ${total} dashboards`}
            }}
            renderItem={(item) => 
                <List.Item 
                    actions={
                        [
                        <a onClick={() => handleClickOpen({title: item.title, url: `https://lookerstudio.google.com/reporting/${item.name}`})} style={{fontWeight: 'bold'}}>select</a>,
                        <Link target='_blank' rel='noopener noreferrer' style={{textDecoration: 'underline'}}href={`https://lookerstudio.google.com/reporting/${item.name}`}>open in looker studio</Link>
                    ]}
                >
                    <Skeleton avatar title={false} loading={item.loading} active>
                        <List.Item.Meta
                            title={<Link style={{color: '#1890ff', fontWeight: 'bold'}} onClick={() => handleClickOpen({title: item.title, url: `https://lookerstudio.google.com/reporting/${item.name}`})}>{item.title}</Link>}
                            description={item.name}
                        />
                    </Skeleton>
                </List.Item>
            }
        /> :
        <Form 
            form={form}
            layout="vertical"
            onFinish={handleFormSubmit}
            style={{marginTop: 36}}
            // onValuesChange={handleChecks}
        >
            <Form.Item
                label="Name"
                name="name"
                rules={[
                    {
                        required: true,
                        message: 'Please input a name for your report!',
                    },
                ]}
            >
                <Input value={inputName} onChange={handleInputNameChange}/>
            </Form.Item>
            <Form.Item
                label="URL"
                name="url"
                rules={[
                    {
                        required: true,
                        message: 'Please paste a URL for your report!',
                    },
                ]}
            >
                <Input value={inputURL} onChange={handleInputURLChange}/>
            </Form.Item>
        </Form>}
    </Modal>
  );
}