import { Dropdown, Menu as AntdMenu } from 'antd';
import styled from 'styled-components';
import { MoreVertical } from 'react-feather';
import { CopyOutlined, SettingOutlined, EditOutlined, SaveOutlined, FolderOpenOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { useSecurityContext } from '../../hooks';
import { Fragment, useEffect, useState } from 'react';

const { Item } = AntdMenu;


const Menu = styled(AntdMenu)`
  max-height: 320px;
  overflow: hidden auto;
  padding-top: 0;
  li.ant-dropdown-menu-item-active {
    background: var(--primary-9-color);
  }
`;


const TabOptions = ({
  currentTab, 
  tabs, 
  setActiveId, 
  handleDoubleClick, 
  closeTab, 
  openNewTab, 
  showQuerySettingsModal, 
  showSaveQueryModal, 
  showOpenQueryModal,
  showShareAudienceModal,
  saveQueryToDb,
  tabMode,
  updateSchemaVersion
}) => {

  const [department, setDepartment] = useState('Company');

  let { currentToken, getUserDepartment} = useSecurityContext();
  

  useEffect(() => {
    setDepartment(getUserDepartment());
  }, [currentToken, getUserDepartment])

  let defaultItems = [
    {
      key: '1',
      label: <span><FolderOpenOutlined style={{paddingRight: 4}}/>Open</span>,
    },
    {
      key: '2',
      label: <span><EditOutlined style={{paddingRight: 4}}/>Rename</span>,
    },
    {
      key: '3',
      label: <span><CopyOutlined style={{paddingRight: 4}}/>Duplicate</span>,
    },
    {
      key: '5',
      label: <span><SaveOutlined style={{paddingRight: 4}}/>Save</span>,
      disabled: (!('measures' in currentTab.query) && !('dimensions' in currentTab.query)) || !currentTab.queryId
    },
    {
      key: '6',
      label: <span><SaveOutlined style={{paddingRight: 4}}/>Save As</span>,
      disabled: !('measures' in currentTab.query) && !('dimensions' in currentTab.query)
    },
  ];

  defaultItems =  tabMode !== 'audience' ? defaultItems.concat([
    {
      key: '4',
      label: <span><SettingOutlined style={{paddingRight: 4}}/>Settings</span>,
    },
  ]) : defaultItems.concat([
    {
      key: '4',
      label: <span><UsergroupAddOutlined style={{paddingRight: 4}}/>Share</span>,
      disabled: !currentTab.queryId
    },
  ]);

  const items = defaultItems.concat([
    {
      key: '7',
      label: tabs.length === 1 ? <span style={{color: 'red', opacity: '0.65'}}>Close</span> : <span style={{color: 'red'}}>Close</span>,
      disabled: tabs.length === 1,
    },
  ])


  const handleMenuClick = (e) => {
    if (e.key === '1'){
      // Open
      showOpenQueryModal();
    }
    if (e.key === '2'){
      // Rename
      handleDoubleClick();
    }
    if (e.key === '3'){
      // Duplicate
      openNewTab(currentTab.query);
    }
    if (e.key === '4' && tabMode === 'query'){
      // Open Settings
      showQuerySettingsModal();
    }
    if (e.key === '4' && tabMode === 'audience'){
      // Open Share
      showShareAudienceModal();
    }
    if (e.key === '5'){
      // Save
      saveQueryToDb(tabMode, {name: currentTab.tabName, tab: currentTab, flag: 'update', id: currentTab.queryId}).then(
        (data) => {
          if(tabMode === "audience"){
            updateSchemaVersion()
          }
        }
      );
    }
    if (e.key === '6'){
      // Save As
      showSaveQueryModal();
    }
    if (e.key === '7'){
      // Close
      closeTab(currentTab.id);
    }
  };
  
  const menu = (
    <Menu onClick={handleMenuClick}>
      {items.map((item) => (
        <Item key={item.key} disabled={item.disabled}>{item.label}</Item>
      ))}
    </Menu>
  );

  return (
    <Fragment>
      <Dropdown
        overlay={menu}
        placement="bottomLeft"
        trigger="click"
      >
          <MoreVertical size={22} style={{paddingTop: 4, margin: 0}} onClick={() => (setActiveId(currentTab.id))}/> 
      </Dropdown>
    </Fragment>
  );
};


export default TabOptions;