import React, { useState, useEffect } from 'react';
import { Space, Select, Modal, Typography } from 'antd';
import { Alert } from '../../atoms';
import { useSecurityContext } from '../../hooks';
import { backendApiUrl } from '../../config';

const { Text } = Typography;


export function ShareAudienceModal({ activeId, tabs, isModalOpen, setIsModalOpen, saveQueryToDb }) {

  const { getUserDepartment, currentToken } = useSecurityContext();
  const [userDepartment, setUserDepartment] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState([]);
  const [currentTab, setCurrentTab] = useState({tabName: 'Query 1'});
  const [confirmLoading, setConfirmLoading] = useState(false);
  const excludeDepartments = [
    'Company', 'Strategy & Data', 'Audience Growth', 'Magazines', 'Operation Santa Claus', 'Technology', 'Facilities Management', 
    'Events & Corporate Communications', 'Strategy and Special Projects', 'Legal & Corporate Secretarial', 'Management', 'Administration'
  ]
  
  const getDepartmentsFromDb = () => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
    };
    fetch(`${backendApiUrl}/departments`, requestOptions)
      .then(response => response.json())
      .then(data => {

        setDepartments(
          [
            {label: "With SCMP", options: data.filter(d => d.name === 'Company').map(dept => ({value: dept.id, label: dept.name}))},
            {label: "With 1 department", options: data.filter(d => !excludeDepartments.includes(d.name)).map(dept => ({value: dept.id, label: dept.name}))},
          ]
        )
      });
  }

  const getAudienceFromDb = (queryId) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
    };
    fetch(`${backendApiUrl}/audience/${queryId}`, requestOptions)
        .then(response => response.json())
        .then(data => {
          setSelectedDepartmentId(data.departments.map(dept => dept.id) || [])
        });
  }

  useEffect(() => {
    setUserDepartment(getUserDepartment());
    // call GET departments
    getDepartmentsFromDb();
  }, [])

  useEffect(() => {
    const queryId = tabs.filter(tab => tab.id === activeId)[0]['queryId'];
    setCurrentTab(tabs.filter(tab => tab.id === activeId)[0]);
    if (queryId){
      getAudienceFromDb(queryId)
    }
  }, [activeId, tabs])

  const handleOk = async () => {
    setConfirmLoading(true);
    await saveQueryToDb('audience', {id: currentTab['queryId'], name: currentTab.tabName, tab: currentTab, departments: selectedDepartmentId, flag: 'update'})
    setConfirmLoading(false)
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    getAudienceFromDb(currentTab['queryId'])
    setIsModalOpen(false);
  };

  return (
    <Modal 
      title="Share Audience with..." 
      open={isModalOpen} 
      onOk={handleOk} 
      onCancel={handleCancel} 
      confirmLoading={confirmLoading} 
      width={800}
    >
      <Alert style={{marginBottom: 20}} message={`Allow other users to use the audience. However the audience can only by modified by you.`} type="info" showIcon/>
      <Space direction="vertical">
          <Text>Pick one or more department(s): </Text>
          <Select
              options={departments}
              value={selectedDepartmentId}
              onChange={(value) => {
                setSelectedDepartmentId(value);
              }}
              mode="multiple"
              style={{
                width: 340,
              }}
          />
      </Space>
    </Modal>
  );
}