
import { Typography } from 'antd';

const { Title } = Typography;

export default function Footer() {
  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop:'48px', alignItems:'center', marginBottom: '24px'}}>
      
    <a href="https://scmp.slack.com/messages/CBBBQEZPC" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/scmp_logo.svg`} alt="Slack logo with hyperlink to slack channel" width='32'/>
    </a>
    {/* <Typography style={{marginTop: '16px'}}>Made with by your data team</Typography> */}
    </div>
  );
}