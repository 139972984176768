import React, { useEffect, useState } from 'react';
import { Modal, List, Skeleton, Popconfirm as AntdPopconfirm, notification, Input, Space} from 'antd';
import { backendApiUrl } from '../../config';
import { useSecurityContext } from '../../hooks';
import { DeleteTwoTone, WarningTwoTone } from '@ant-design/icons';
import styled from 'styled-components';
import { Alert } from '../../atoms';
import { useHistory } from 'react-router';
import moment from 'moment';
import { capitalize } from '../../utils';

const { Search } = Input;

const Link = styled.a`
&& {
    color: #f1494b;
    &:hover {
        opacity: 0.8;
      }s
}
`;

const Popconfirm = styled(AntdPopconfirm)`
& div.ant-popover-inner {
    padding: 10px;
  }
`

export function OpenQueryModal({ activeId, tabs, isModalOpen, setIsModalOpen, userQueries, getQueriesFromDb, saveTabs, tabMode, updateSchemaVersion }) {

  const [userDepartment, setUserDepartment] = useState(null);
  const [currentTab, setCurrentTab] = useState({tabName: 'Query 1', query: {}})
  const {currentToken, getUserDepartment } = useSecurityContext();
  const { push } = useHistory();
  const [searchedQuery, setSearchedQuery] = useState('');

  useEffect(() => {
    getQueriesFromDb(tabMode);
    setUserDepartment(getUserDepartment());
  }, [])

  useEffect(() => {
    setCurrentTab(tabs.filter(tab => tab.id === activeId)[0]);
  }, [activeId, tabs])
  
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const makeDisplayQuery = (query) => {
    return (
        <ul style={{ width: '300px'}}>
        <li style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
            metrics: {query.measures ? query.measures.toString() : "n/a"}
        </li>
        <li style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
            dimensions: {query.dimensions ? query.dimensions.toString(): "n/a"}
        </li>
    </ul>)
  }

  const makeDisplayAudience = (user_id_dimension) => {
    return (
        <ul style={{ width: '300px'}}>
        <li style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
            user id type: {user_id_dimension}
        </li>
    </ul>)
  }

  const deleteQueryFromDb = (tabMode, query) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
    };
    fetch(`${backendApiUrl}/${tabMode}/${query.id}`, requestOptions)
        .then(response => response.json())
        .then(data => notification.open({
            message: `${query.name} successfully deleted!`,
            icon: <DeleteTwoTone />,
          }))
        .catch(err => notification.open({
          message: `This query is linked to a dashboard pipeline. Please unlink it first.`,
          icon: <WarningTwoTone />,
        }))
        .then(
          (data) => {
            if(tabMode === "audience"){
              updateSchemaVersion();
            }
          }
        )
        .then(data => getQueriesFromDb(tabMode));
  }

  const onDeleteCancel = (e) => {

  }

  const onDeleteConfirm = (tabMode, item) => {
    deleteQueryFromDb(tabMode, item);
  }

  const handleClickOpen = (item) => {
    const nextTabs = tabs.map(tab => {
      if(tab.id === activeId){
        return {...tab, tabName: item.name, GASchema: item.ga_schema, mode: item.mode, query: JSON.parse(item.query_obj), renderProps: {}, chartOption: 'chart', chartToDisplay: 'line', queryId: item.id}
      }
      return tab
    })
    saveTabs({
      activeId: activeId,
      tabs: nextTabs,
    });
    push({ search: `?query=${encodeURIComponent(item.query_obj)}` });
    setIsModalOpen(false);
  }

  return (
    <Modal title={`Open ${capitalize(tabMode)}`} open={isModalOpen} footer={null} onCancel={handleCancel} width={800}>
        {('measures' in currentTab.query || 'dimensions' in currentTab.query) && <Alert style={{marginBottom: 20}} message={`Opening a ${tabMode} will replace current ${tabMode}`} type="warning" showIcon/>}
        <Search
            placeholder="input search text"
            onSearch={(value) => setSearchedQuery(value)}
            onChange={(e) => setSearchedQuery(e.target.value)}
            style={{
                width: 200,
                marginBottom: 10
            }}
        />
        <List 
            dataSource={userQueries.filter(query => query.name.toLowerCase().includes(searchedQuery.toLowerCase()))}
            pagination={{
                total: userQueries.length,
                pageSize: 5,
                showTotal: (total) => { return `Total ${total} queries`}
            }}
            renderItem={(item) => 
                <List.Item 
                    actions={
                      [
                        <a onClick={() => handleClickOpen(item)}>open</a>, 
                        <Popconfirm
                            title={`Are you sure to delete this ${tabMode}?`}
                            onConfirm={() => onDeleteConfirm(tabMode, item)}
                            onCancel={onDeleteCancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Link>delete</Link>
                        </Popconfirm>
                    ]}
                >
                    <Skeleton avatar title={false} loading={item.loading} active>
                        <List.Item.Meta
                            title=<Link style={{color: '#1890ff', fontWeight: 'bold'}} onClick={() => handleClickOpen(item)}>{item.name}</Link>
                            description={tabMode === "query" ? makeDisplayQuery(JSON.parse(item.query_obj)) : makeDisplayAudience(item.user_id_dimension)}
                        />
                        <div style={{paddingRight: 40, fontSize: '90%', width: 220}}>
                          <Space direction="vertical">
                            <p style={{marginBottom: 0}}>{moment(item.created).format('MMM Do, h:mm:ss a')}</p>
                            {item.bq_table_name && <Space>
                              <img src={`${process.env.PUBLIC_URL}/logo_bigquery.jpg`} alt="bigquery logo" width='30'/>
                              <p style={{width: 160, marginBottom: 0, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}} >{item.bq_table_name}</p>
                            </Space>}
                          </Space>
                          
                        </div>
                        <div>{item.ga_schema}</div>
                    </Skeleton>
                </List.Item>
            }
        />
    </Modal>
  );
}