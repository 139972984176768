import { Fragment, useEffect, useState } from 'react';
import { Popover } from 'antd';
import { Select } from '../../atoms';
import { InfoCircleOutlined as AntdInfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { getReportingsFromMeta } from '../../config';
import { useHistory } from 'react-router-dom';
import { getArrayOfCubesFromQuery } from '../../config';
import { useQueryContext } from '../QueryContext';


const InfoCircleOutlined = styled(AntdInfoCircleOutlined)`
  color: var(--disabled-color);
  &:hover {
    color: var(--primary-5-color);
  }
`;

const TooltipMarkdown = styled(ReactMarkdown)`
  white-space: pre-wrap;
`

export default function DatasetSelector({ GASchema, setGASchema, mode, schemaChangedByUser, setSchemaChangedByUser, metaExtended, updateQuery }){
    const [options, setOptions] = useState([]);
    const [dataset, setDataset] = useState(GASchema);
    const { push } = useHistory();
    const { query } = useQueryContext();

    useEffect(() => {
        if (mode === 'reporting' && GASchema === 'GA4'){
            const reports = getReportingsFromMeta(metaExtended);
            setOptions(Object.keys(reports).map(report => {return {value: report, label: report}}));
            // when query is already provided (click on exodus link)
            if(query && 'measures' in query && getArrayOfCubesFromQuery(query, metaExtended).length > 0){
                const reportToSet = Object.keys(reports).find(key => reports[key].includes(query.measures[0]));
                setDataset(reportToSet);
                changeQueryOnQueryBuilder(reports[reportToSet]);
            } else {
                setDataset(Object.keys(reports)[0]);
                changeQueryOnQueryBuilder(reports[Object.keys(reports)[0]]);
            }            
        } else {
            setDataset(GASchema)
            setOptions(
                ['UA', 'UA + Apple'].includes(GASchema) && mode === 'query' ? [
                {
                    value: 'UA',
                    label: 'Google Analytics',
                },
                {
                    value: 'UA + Apple',
                    label: 'Google Analytics + Apple News',
                },
            ] : ['UA', 'UA + Apple'].includes(GASchema) && mode === 'reporting' ? [
                {
                    value: 'UA',
                    label: 'Google Analytics',
                },
            ] : ['GA4', 'GA4 + Apple'].includes(GASchema) ? [
                {
                    value: 'GA4',
                    label: 'Google Analytics',
                },
                {
                    value: 'GA4 + Apple',
                    label: 'Google Analytics + Apple News',
                },
            ] : [{value: 'cube', label: 'Cube Logs',}])
        }
    }, [GASchema, mode])

    const handleSchemaChange = (changed) => {
        
        // When clicked on a report then we select all measures of that report and update the query
        if (mode === 'reporting' && GASchema === 'GA4'){
            const reports = getReportingsFromMeta(metaExtended);
            changeQueryOnQueryBuilder(reports[changed]);
            setDataset(changed);
        } else {
            setSchemaChangedByUser(schemaChangedByUser + 1);
            setGASchema(changed);
        }
        
    }

    const changeQueryOnQueryBuilder = (report) => {
        const query = {measures: (report || []), timeDimensions: [{dimension: 'ManagementEvents.date', dateRange: 'Last month', granularity: 'month'}]};
        updateQuery(query);
        push({ search: `?query=${encodeURIComponent(JSON.stringify(query))}` });
    }

    return (
        <Fragment>
            <Select
                value={dataset}
                style={{
                    width: 260,
                }}
                onChange={handleSchemaChange}
                options={options}
                />
                <Popover 
                    overlayStyle={{zIndex: '9900'}} 
                    overlayInnerStyle={{paddingBottom: '8px', paddingRight: '8px', paddingLeft: '8px', maxWidth: '320px'}} 
                    placement="right" 
                    title="Which dataset to choose?"
                    content={<TooltipMarkdown linkTarget="_blank">{`The combined Google Analytics and Apple News dataset has fewer metrics and dimensions available 
                                but it reports Pageviews and Users from both GA and Apple News.\n\n**Attention**: Changing the dataset will automatically clear the query.`}
                            </TooltipMarkdown>} 
                    trigger="hover"
                >
                    <InfoCircleOutlined style={{marginLeft: '12px'}} />
                </Popover>
        </Fragment>
    )
};