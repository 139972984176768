import React, { useState, useEffect } from 'react';
import { Space, Segmented, Modal, Typography } from 'antd';
import { useQueryContext } from '../QueryContext';
import { useSecurityContext } from '../../hooks';

const { Text } = Typography;


export function QuerySettingsModal({ tempSchema, setTempSchema, GASchema, setGASchema, tempMode, setTempMode, mode, setMode, isModalOpen, setIsModalOpen }) {

  const { setSchemaChangedByUser, schemaChangedByUser } = useQueryContext();
  const { getUserDepartment } = useSecurityContext();
  const [userDepartment, setUserDepartment] = useState(null);

  const gaSchemas = [
    {
      label: 'UA',
      value: 'UA',
    },
    {
      label: 'GA4',
      value: 'GA4',
    }];

  const uaSchema = [{
    label: 'UA',
    value: 'UA',
  }];

  const allSchemas = gaSchemas.concat([
    {
      label: 'Cube',
      value: 'cube',
    },
  ]);
  

  useEffect(() => {
    setUserDepartment(getUserDepartment());
  }, [GASchema])

  useEffect(() => {
    if (GASchema.includes('Apple')){
      setGASchema(GASchema.startsWith('UA') ? 'UA': 'GA4')
    }
  }, [mode])

  const handleOk = () => {
    setIsModalOpen(false);
    setSchemaChangedByUser(schemaChangedByUser + 1);
    setGASchema(tempSchema);
    setMode(tempMode);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setTempSchema(GASchema);
    setTempMode(mode);
  };

  return (
    <Modal title="Query Settings" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <Space direction="vertical">
          <Text>Schema</Text>
          <Segmented
            options={
                ['Consumer Subscription, Audience & Data'].includes(userDepartment) ? allSchemas 
              :  gaSchemas
            }
            value={['UA', 'UA + Apple'].includes(tempSchema) ? 'UA': ['GA4', 'GA4 + Apple'].includes(tempSchema) ? 'GA4' : 'cube'}
            onChange={setTempSchema}
            style={{marginBottom: 16}}
          />
          {['Consumer Subscription, Audience & Data', 'CEO Office', 'CEO office'].includes(userDepartment) && <Text>Mode</Text>}
          {['Consumer Subscription, Audience & Data', 'CEO Office', 'CEO office'].includes(userDepartment) && 
              <Segmented
                options={[{label: "Query", value: "query"}, {label: "Reporting", value: "reporting"}]}
                value={tempMode}
                onChange={setTempMode}
              />
          }
      </Space>
    </Modal>
  );
}