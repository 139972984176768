import React from 'react';
import { Button, Card } from '../atoms';
import { Typography } from 'antd';
import { GoogleLogin } from '@react-oauth/google';
import { useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import {
  useSecurityContext,
  useLocalStorage,
  useDeepEffect,
} from '../hooks';
import { nanoid } from 'nanoid'
import { createCodeChallenge, createCodeVerifier } from '../utils';
import LoginSteps from '../components/LoginSteps';

const { Title } = Typography;

export function LoginPage() {
  
  const { token: securityContextToken, removeCubeToken, setGoogleFullRes, isLoggedIn } = useSecurityContext()
  const [savedCode, setCode, removeCode] = useLocalStorage('code_verifier', null);
  const { push } = useHistory();

  const onClickSignIn = async () => {
    const state = nanoid();
    let codeVerifier = createCodeVerifier(50);
    setCode(codeVerifier);
    let codeChallenge = await createCodeChallenge( codeVerifier ).then( codeChallenge => {
      return codeChallenge
    })
    window.open(`${process.env.REACT_APP_OAUTH_BASE_URL}/sign?client_id=${process.env.REACT_APP_OAUTH_CLIENT_ID}&client_secret=${process.env.REACT_APP_OAUTH_CLIENT_SECRET}&redirect_uri=${process.env.REACT_APP_OAUTH_REDIRECT_URI}&response_type=code&state=${state}&code_challenge=${codeChallenge}&code_challenge_method=S256`, "_self", "noreferrer")
  }

  const responseGoogle = (response) => {
    removeCubeToken();
    setGoogleFullRes(jwtDecode(response.credential));
    // refreshGoogleResSetup(response);
    if (!securityContextToken){
      onClickSignIn();
    }
  }

  const failedCallback = (error) => {
    console.log(error);
  }

  useDeepEffect(() => {
    if(isLoggedIn){
      push('/')
    }
  }, [isLoggedIn])


  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center'}}>
      <LoginSteps />
      <Card style={{width: '340px', marginBottom: '76px', paddingBottom: '24px'}}>
        <div style={{display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems:'center'}}>
          <img src={`${process.env.PUBLIC_URL}/logo_google.jpg`} alt="google logo" width='290'/>
          <GoogleLogin
            onSuccess={responseGoogle}
            onError={failedCallback}
            auto_select
            // hosted_domain='scmp.com'
            size="large"
            theme="filled_blue"
          />
        </div>
      </Card>
    </div>
  );
}