import { useState } from 'react';
import { List, Typography, Card, Badge, Space, Row, Col } from 'antd';
import  { CopyOutlined, LinkOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useQueryContext } from '../QueryContext';

const { Title, Text } = Typography;

const Link = styled.a`
&& {
    color: #f1494b;
    &:hover {
        opacity: 0.8;
      }s
}
`;

const StyledCard = styled(Card)`
  margin: 6px;
  border-radius: 6px;
  background-color: #FAFAFA;
  
  ${'' /* width: 100%; */}
  &.ant-card-head {
    position: sticky;
    top: 0;
    z-index: 100;
    background: white;
    border-bottom-color: #f6f6f8;
  }
  &ant-card-body {
    max-width: 100%;
    overflow: auto;
    position: relative;
  }
  .ant-tabs > .ant-tabs-nav {
    margin-bottom: 8px;
  }
  @media only screen and (max-width: 1800px) {
      height: 98px;
  }
`;


export default function Recommendations({
  title,
  data,
  type,
  updateQuery,
}) {
  const { push } = useHistory();
  const { setGASchema } = useQueryContext();

  const handleClickOpen = (item) => {
    updateQuery(item.query);
    if ('schema' in data){
        setGASchema(data.schema);
    }
    push({ search: `?query=${encodeURIComponent(JSON.stringify(item.query))}` });
    window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
  }

  return (
    <div>
        <Title style={{color: '#5B5C7D'}} level={4}>
            {title}
        </Title>
      <List
          grid={{ gutter: 16, column: 2 }}
          dataSource={data}
          renderItem={(item) => (
            
            <StyledCard hoverable={true} onClick={() => handleClickOpen(item)}>
                <Col>
                    <Row style={{width: '100%'}}>
                        <div style={{color: '#00000073'}}>
                        {'entity' in item && <Text strong type="secondary" style={{fontSize: 10}}>
                            {item.entity.toUpperCase()} •  {'  '}
                        </Text>}
                        <Text strong type="secondary" style={{fontSize: 10}}>
                            {item.dateRange.toUpperCase()}
                        </Text>
                        </div>
                    </Row>
                    <Text strong>{item.title}</Text>
                </Col>
                </StyledCard>
          )}
        />
    </div>
  );
}