import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSecurityContext } from '../hooks';


function PrivateRoute({ children, ...rest }) {
    let { isLoggedIn } = useSecurityContext();
    return (
      <Route
        {...rest}
        render={({ location }) =>
        isLoggedIn ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }

export default PrivateRoute;