
export const getDrupalQuery = (articleID, limit) => ({
    dimensions: [
      "Drupal.article_id", "Drupal.title", "Drupal.publication_date", "Drupal.authors_name",
      "Drupal.sub_sections", "Drupal.desk", "Drupal.content_lock", "Drupal.url", "Drupal.keywords",
      "Drupal.topics", "Drupal.word_count", "DrupalArticleImages.image_url", "Drupal.user_needs",
    ], 
    filters: [{member: "Drupal.article_id", operator: "equals", values: [articleID]}],
    limit: limit,
  });
  
export const getArticleGAQuery = (articleID, limit) => ({
    measures: ["ArticleGA.loy_reg_users"],
    filters: [{member: "ArticleGA.article_id", operator: "equals", values: [articleID]}],
    limit: limit,
  });
  
export const getArticleUnionQuery = (articleID, limit) => ({
    measures: ["ArticleAppleGAUnion.users"],
    filters: [{member: "ArticleAppleGAUnion.article_id", operator: "equals", values: [articleID]}],
    limit: limit,
  });
  
export const getArticleCountryUnionQuery = (articleID, limit) => ({
    dimensions: ["ArticleCountryAppleGAUnion.country"],
    measures: ["ArticleCountryAppleGAUnion.users"],
    filters: [{member: "ArticleCountryAppleGAUnion.article_id", operator: "equals", values: [articleID]}],
    limit: limit,
  });
  
export const getArticlePlatformUnionQuery = (articleID, limit) => ({
    dimensions: ["ArticlePlatformAppleGAUnion.platform"],
    measures: ["ArticlePlatformAppleGAUnion.users"],
    filters: [{member: "ArticlePlatformAppleGAUnion.article_id", operator: "equals", values: [articleID]}],
    limit: limit,
  });
  
export const getArticleTrafficSourceUnionQuery = (articleID, limit) => ({
    dimensions: ["ArticleTrafficSourceAppleGAUnion.traffic_source"],
    measures: ["ArticleTrafficSourceAppleGAUnion.users"],
    filters: [{member: "ArticleTrafficSourceAppleGAUnion.article_id", operator: "equals", values: [articleID]}],
    limit: limit,
  });
  
export const getArticleGARegUsersQuery = (articleID, limit) => ({
    measures: ["ArticleGARegisteredUsers.active_subscribers"],
    filters: [{member: "ArticleGARegisteredUsers.article_id", operator: "equals", values: [articleID]}],
    limit: limit,
  });
  
export const getArticleDayGAQuery = (articleID, limit, dateRange) => ({
    measures: ["ArticleDayGA.exit_rate", "ArticleDayGA.article_pageviews_fifty_percent", "ArticleDayGA.article_pageviews_hundred_percent"],
    timeDimensions: [{
      dimension: "ArticleDayGA.date",
      dateRange: dateRange,
    }],
    filters: [{member: "ArticleDayGA.article_id", operator: "equals", values: [articleID]}],
    limit: limit,
  });
  
export const getArticleDayUnionQuery = (articleID, limit, dateRange) => ({
    measures: ["ArticleDayAppleGAUnion.pageviews", "ArticleDayAppleGAUnion.time_on_page"],
    timeDimensions: [{
      dimension: "ArticleDayAppleGAUnion.date",
      dateRange: dateRange,
    }],
    filters: [{member: "ArticleDayAppleGAUnion.article_id", operator: "equals", values: [articleID]}],
    limit: limit,
  });
  
export const getArticleDayGARegUsersQuery = (articleID, limit, dateRange) => ({
    measures: ["ArticleDayGARegisteredUsers.comments", "ArticleDayGARegisteredUsers.new_subscribers"],
    timeDimensions: [{
      dimension: "ArticleDayGARegisteredUsers.date",
      dateRange: dateRange,
    }],
    filters: [{member: "ArticleDayGARegisteredUsers.article_id", operator: "equals", values: [articleID]}],
    limit: limit,
  });
  
export const getArticleDailyPageviewsQuery = (articleID, limit, dateRange) => ({
    measures: ["ArticleDayAppleGAUnion.pageviews"],
    timeDimensions: [{
      dimension: "ArticleDayAppleGAUnion.date",
      dateRange: dateRange,
      granularity: "day",
    }],
    filters: [{member: "ArticleDayAppleGAUnion.article_id", operator: "equals", values: [articleID]}],
    limit: limit,
  });


export const getExploreArticleCountryUnionQuery = (articleID, dateRange) => ({
    dimensions: ["GA4AppleUnion.country"],
    measures: ["GA4AppleUnion.users"],
    timeDimensions: [{
        dimension: "GA4AppleUnion.date",
        dateRange: dateRange
    }],
    filters: [{member: "DrupalGA4Apple.article_id", operator: "equals", values: [articleID]}],
});


export const getExploreArticlePlatformUnionQuery = (articleID, dateRange) => ({
    dimensions: ["GA4AppleUnion.platform"],
    measures: ["GA4AppleUnion.users"],
    timeDimensions: [{
        dimension: "GA4AppleUnion.date",
        dateRange: dateRange
    }],
    filters: [{member: "DrupalGA4Apple.article_id", operator: "equals", values: [articleID]}],
});


export const getExploreArticleTrafficSourceUnionQuery = (articleID, dateRange) => ({
    dimensions: ["GA4AppleUnion.traffic_source"],
    measures: ["GA4AppleUnion.users"],
    timeDimensions: [{
        dimension: "GA4AppleUnion.date",
        dateRange: dateRange
    }],
    filters: [{member: "DrupalGA4Apple.article_id", operator: "equals", values: [articleID]}],
});


export const getExploreArticleDailyPageviewsQuery = (articleID, dateRange) => ({
    measures: ["GA4AppleUnion.pageviews"],
    timeDimensions: [{
        dimension: "GA4AppleUnion.date",
        dateRange: dateRange,
        granularity: "day",
    }],
    filters: [{member: "DrupalGA4Apple.article_id", operator: "equals", values: [articleID]}],
});