import { validateQuery } from '@cubejs-client/core';
import { Tabs, Spin, Input, notification  } from 'antd';
import { SaveTwoTone } from '@ant-design/icons';
import equals from 'fast-deep-equal';
import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import TabOptions from './TabOptions';
import jwtDecode from 'jwt-decode';


import { DrilldownModal } from '../DrilldownModal/DrilldownModal';
import { useQueryContext } from '../QueryContext';
import { useMetaContext } from '../MetaContext';
import { QuerySettingsModal } from './QuerySettingsModal';
import { SaveQueryModal } from './SaveQueryModal';
import { OpenQueryModal } from './OpenQueryModal';
import { playgroundContext, buildApiUrl, backendApiUrl } from '../../config';
import { useSecurityContext } from '../../hooks';
import { capitalize } from '../../utils';
import { ShareAudienceModal } from './ShareAudienceModal';

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  && {
    .ant-tabs-nav {
      background:var(--layout-body-background);
      margin: 0;
    }
    .ant-tabs-extra-content {
      margin-left: 32px;
    }
    .ant-tabs-tab.ant-tabs-tab-with-remove {
      padding: 4px 2px 4px 2px;
      border-radius: 8px 8px 0 0;
      border-left: #fff;
    }
    button.ant-tabs-tab-remove {
      margin: 0;
    }
  }
`;

export function QueryTabs({
  query,
  tabMode,
  children,
  sidebar = null,
  onTabChange,
}) {

  const { GASchema, setGASchema, mode, setMode, renderProps, chartOption, chartToDisplay } = useQueryContext();
  const { currentToken } = useSecurityContext();
  const { fetchCubeJsMeta } = useMetaContext();
  const [ready, setReady] = useState(false);
  const [isQuerySettingsModalOpen, setIsQuerySettingsModalOpen] = useState(false);
  const [isShareAudienceModalOpen, setIsShareAudienceModalOpen] = useState(false);
  const [isSaveQueryModalOpen, setIsSaveQueryModalOpen] = useState(false);
  const [isOpenQueryModalOpen, setIsOpenQueryModalOpen] = useState(false);
  const [tempSchema, setTempSchema] = useState(GASchema);
  const [tempMode, setTempMode] = useState(mode);
  const [userQueries, setUserQueries] = useState([]);
  const [schemaVersion, setSchemaVersion] = useState(0);
  const { basePath, baseUrl } = playgroundContext;
  const apiUrl = buildApiUrl(baseUrl, basePath);

  const getSchemaVersion = () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
    };
    fetch(`${backendApiUrl}/schema_version`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setSchemaVersion(data["value"])
        });
  }

  const updateSchemaVersion = () => {
      // we update the schema version
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
        body: JSON.stringify({ 
            value: schemaVersion + 1,
        })
     }
      fetch(`${backendApiUrl}/schema_version`, requestOptions)
        .then(response => {
            setSchemaVersion(schemaVersion + 1);
            fetchCubeJsMeta(apiUrl, currentToken);
          }
        )
  }

  const getQueriesFromDb = (tabMode) => {
    const payload = jwtDecode(currentToken);
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
    };
    const path = tabMode === "query" ? "queries" : "audiences";
    fetch(`${backendApiUrl}/${path}?sub=${payload.sub}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setUserQueries(data)
        });
  }

  const updateQueryTable = (tableName) => {
    if (userQueries.map(query => query.bq_table_name).includes(tableName)){
      const query = userQueries.filter(query => query.bq_table_name === tableName)[0];
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
        body: JSON.stringify({ 
            name: query.name,
            query: JSON.parse(query.query_obj),
            ga_schema: query.ga_schema,
            mode: query.mode,
            bq_table_name: tableName,
            partition_name: query.partition_name,
        })
     }
      fetch(`${backendApiUrl}/query/${query.id}`, requestOptions)
        .then(response => response.json())
        .then(data => getQueriesFromDb("query"));
    }
  }

  const saveQueryToDb = async (tabMode, params) => {
    if('flag' in params && params['flag'] === 'update'){
      if('bq_table_name' in params && params.bq_table_name){
        updateQueryTable(params.bq_table_name)
      }
      // update
      const queryId = params.id;
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
        body: tabMode === 'query' ? JSON.stringify({ 
            name: params.name,
            query: params.tab.query,
            ga_schema: params.tab.GASchema,
            mode: params.tab.mode,
            bq_table_name: params.bq_table_name || null,
            partition_name: params.partition_name || null,
        }) : JSON.stringify({ 
          name: params.name,
          query: params.tab.query,
          ga_schema: params.tab.GASchema,
          user_id_dimension: params.tab.query.dimensions[0].includes('user_pseudo_id') ? 'user_pseudo_id' : 'mixed_user_id',
          departments: params.departments,
        })
     }
      fetch(`${backendApiUrl}/${tabMode}/${queryId}`, requestOptions)
        .then(response => {
          if (tabMode === 'audience'){
            updateSchemaVersion()
          }
          return response.json()
        })
        .then(data => notification.open({
            message: `${params.name} successfully saved!`,
            icon: <SaveTwoTone />,
          }))
        .then(data => getQueriesFromDb(tabMode));
    } else {
      if('bq_table_name' in params && params.bq_table_name){
        updateQueryTable(params.bq_table_name)
      }
      // create query
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
        body: tabMode === 'query' ? JSON.stringify({ 
            name: params.name,
            query: params.tab.query,
            ga_schema: params.tab.GASchema,
            mode: params.tab.mode,
            bq_table_name: params.bq_table_name || null,
            partition_name: params.partition_name || null,
            is_bq_table_productionised: params.is_bq_table_productionised || false,
        }) : JSON.stringify({ 
          name: params.name,
          query: params.tab.query,
          ga_schema: params.tab.GASchema,
          user_id_dimension: params.tab.query.dimensions[0].includes('user_pseudo_id') ? 'user_pseudo_id' : 'mixed_user_id',
        })
      }
      const path = tabMode === "query" ? "queries" : "audiences";
      const queryId = await fetch(`${backendApiUrl}/${path}`, requestOptions)
        .then(response => {
          if (tabMode === 'audience'){
            updateSchemaVersion()
          }
          return response.json()
        })
        .then(data => {
          notification.open({
            message: `${params.name} successfully saved!`,
            icon: <SaveTwoTone />,
          })
          return data['id'];
        })
      getQueriesFromDb(tabMode);
      return queryId;
    }

  }
    

  const [queryTabs, saveTabs] = useState({
    activeId: '1',
    tabs: [
      {
        id: '1',
        tabName: `${capitalize(tabMode)} 1`,
        query: query || {},
        GASchema: GASchema || 'GA4',
        mode: mode || 'query',
        renderProps: renderProps || {},
        chartOption: chartOption || 'chart',
        chartToDisplay: chartToDisplay || 'line',
        queryId: null,
      },
    ],
  });

  const [drilldownConfig, setDrilldownConfig] = useState({});
  const [inputValue, setInputValue] = useState(queryTabs.tabs[0].tabName);
  const [editing, setEditing] = useState(false);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleDoubleClick = () => {
    setEditing(true);
    setInputValue(tabs.filter(tab => tab.id === queryTabs.activeId)[0].tabName);
  };

  const handleBlur = () => {
    setEditing(false);
  };

  useEffect(() => {
    if (ready) {
      return;
    }

    const currentTab = queryTabs.tabs.find(
      (tab) => tab.id === queryTabs.activeId
    );

    if (query && !equals(validateQuery(currentTab?.query), validateQuery(query))) {
      const id = getNextId();

      saveTabs({
        activeId: id,
        tabs: [...queryTabs.tabs, { id, tabName: inputValue, query, GASchema, mode, renderProps, chartOption, chartToDisplay }],
      });
    }

    getSchemaVersion();

    setReady(true);
  }, [ready]);

  useEffect(() => {
    if (ready && queryTabs.activeId) {
      const activeTab = queryTabs.tabs.find(
        (tab) => tab.id === queryTabs.activeId
      );
      activeTab && onTabChange?.(activeTab);
    }
  }, [ready, queryTabs.activeId]);

  useEffect(() => {
    queryTabs.tabs = queryTabs.tabs.map(tab => {
      if(tab.id === renderProps.id){
        return { ...tab, renderProps: renderProps }
      }
      return tab
    })
    saveTabs({
      activeId: queryTabs.activeId,
      tabs: queryTabs.tabs,
    });
  }, [renderProps])


  useEffect(() => {
    queryTabs.tabs = queryTabs.tabs.map(tab => {
      if(tab.id === queryTabs.activeId){
        return { ...tab, query: query }
      }
      return tab
    })
    saveTabs({
      activeId: queryTabs.activeId,
      tabs: queryTabs.tabs,
    });
  }, [query])


  useEffect(() => {
    queryTabs.tabs = queryTabs.tabs.map(tab => {
      if(tab.id === queryTabs.activeId){
        return {...tab, GASchema: GASchema}
      }
      return tab
    })
    saveTabs({
      activeId: queryTabs.activeId,
      tabs: queryTabs.tabs,
    });
  }, [GASchema])


  useEffect(() => {
    queryTabs.tabs = queryTabs.tabs.map(tab => {
      if(tab.id === queryTabs.activeId){
        return {...tab, mode: mode}
      }
      return tab
    })
    saveTabs({
      activeId: queryTabs.activeId,
      tabs: queryTabs.tabs,
    });
  }, [mode])


  useEffect(() => {
    queryTabs.tabs = queryTabs.tabs.map(tab => {
      if(tab.id === queryTabs.activeId){
        return { ...tab, chartOption: chartOption }
      }
      return tab
    })
    saveTabs({
      activeId: queryTabs.activeId,
      tabs: queryTabs.tabs,
    });
  }, [chartOption])


  useEffect(() => {
    queryTabs.tabs = queryTabs.tabs.map(tab => {
      if(tab.id === queryTabs.activeId){
        return { ...tab, chartToDisplay: chartToDisplay }
      }
      return tab
    })
    saveTabs({
      activeId: queryTabs.activeId,
      tabs: queryTabs.tabs,
    });
  }, [chartToDisplay])


  useEffect(() => {
    queryTabs.tabs = queryTabs.tabs.map(tab => {
      if(tab.id === queryTabs.activeId){
        return { ...tab, tabName: inputValue }
      }
      return tab
    })
    saveTabs({
      activeId: queryTabs.activeId,
      tabs: queryTabs.tabs,
    });
  }, [inputValue])


  // useEffect(() => {
  //   console.log(queryTabs)
  // }, [queryTabs])

  const { activeId, tabs } = queryTabs;

  function getNextId() {
    const ids = tabs.map(({ id }) => id);

    for (let index = 1; index <= tabs.length + 1; index++) {
      if (!ids.includes(index.toString())) {
        return index.toString();
      }
    }

    return (tabs.length + 1).toString();
  }

  function handleTabSave(tab) {
    saveTabs({
      ...queryTabs,
      tabs: tabs.map((currentTab) => {
        return activeId === currentTab.id
          ? {
              ...currentTab,
              ...tab,
            }
          : currentTab;
      }),
    });
  }

  function setActiveId(activeId) {
    saveTabs({ activeId, tabs });
  }

  function handleDrilldownModalClose() {
    setDrilldownConfig({});
  }

  if (!ready || !queryTabs.activeId) {
    return null;
  }

  const openNewTab = (query, name, schema, mode) => {
    const nextId = getNextId();
    saveTabs({
      activeId: nextId,
      tabs: [
        ...tabs,
        {
          id: nextId,
          tabName: name || `${capitalize(tabMode)} ${nextId}`,
          query: query || (mode === 'audience' ? {dimensions: ["RegisteredUsers.user_id"], timeDimensions: [{dimension: "RegisteredUsers.registration_date"}]} : {}),
          GASchema: schema || 'GA4',
          mode: mode || 'query',
          renderProps: {},
          chartOption: 'chart',
          chartToDisplay: 'line',
          queryId: null,
        },
      ],
    });
  }

  const closeTab = (event) => {
    let closedIndex = Number.MAX_VALUE;
    const nextTabs = tabs.filter(({ id }, index) => {
      if (id === event) {
        closedIndex = index;
      }
      return id !== event;
    });

    saveTabs({
      activeId: nextTabs[Math.min(closedIndex, nextTabs.length - 1)].id,
      tabs: nextTabs,
    });
  }

  const showQuerySettingsModal = () => {
    setTempSchema(queryTabs.tabs.filter(tab => tab.id === activeId)[0].GASchema);
    setTempMode(queryTabs.tabs.filter(tab => tab.id === activeId)[0].mode);
    setIsQuerySettingsModalOpen(true);
  };

  const showSaveQueryModal = () => {
    setIsSaveQueryModalOpen(true);
  };

  const showOpenQueryModal = () => {
    setIsOpenQueryModalOpen(true);
  };

  const showShareAudienceModal = () => {
    setIsShareAudienceModalOpen(true);
  }

  return (
    <Fragment>
      <QuerySettingsModal 
        isModalOpen={isQuerySettingsModalOpen}
        setIsModalOpen={setIsQuerySettingsModalOpen}
        tempSchema={tempSchema}
        setTempSchema={setTempSchema}
        tempMode={tempMode}
        setTempMode={setTempMode}
        GASchema={GASchema}
        setGASchema={setGASchema}
        mode={mode}
        setMode={setMode}
      />
      <ShareAudienceModal 
        isModalOpen={isShareAudienceModalOpen}
        setIsModalOpen={setIsShareAudienceModalOpen}
        activeId={activeId}
        tabs={tabs}
        saveQueryToDb={saveQueryToDb}
      />
      <SaveQueryModal
        activeId={activeId}
        tabs={tabs}
        saveTabs={saveTabs}
        isModalOpen={isSaveQueryModalOpen}
        setIsModalOpen={setIsSaveQueryModalOpen}
        getQueriesFromDb={getQueriesFromDb}
        saveQueryToDb={saveQueryToDb}
        tabMode={tabMode}
      />
      <OpenQueryModal
        activeId={activeId}
        tabs={tabs}
        isModalOpen={isOpenQueryModalOpen}
        setIsModalOpen={setIsOpenQueryModalOpen}
        userQueries={userQueries}
        getQueriesFromDb={getQueriesFromDb}
        saveTabs={saveTabs}
        tabMode={tabMode}
        updateSchemaVersion={updateSchemaVersion}
      />
      <StyledTabs
        data-testid="query-tabs"
        activeKey={activeId}
        type="editable-card"
        tabBarExtraContent={{
          right: sidebar,
        }}
        tabBarStyle={{borderColor: 'transparent'}}
        hideAdd={false}
        onChange={setActiveId}
        onEdit={(event) => {
          if (typeof event === 'string') {
            // setEditEvent(event);
          } else {
            openNewTab();
          }
        }}
      >
        {tabs.map((tab) => (
          <TabPane
            key={tab.id}
            data-testid={`query-tab-${tab.id}`}
            tab={
              <div onDoubleClick={handleDoubleClick}>
                {editing && tab.id === queryTabs.activeId ? 
                  <Input 
                    onKeyDown={e => e.stopPropagation()} 
                    bordered={false}
                    onChange={handleInputChange}
                    style={{padding: "4px 16px", fontStyle: "italic", width: `${Math.max(84, inputValue.length * 10)}px`}}
                    value={inputValue}
                    onBlur={handleBlur}
                    onPressEnter={handleBlur}
                    autoFocus
                  /> : 
                    <div style={{padding: "4px 16px"}}>{tab.renderProps.isLoading && tab.id !== activeId ? <Spin>{tab.tabName}</Spin> : tab.tabName}</div>
                }
              </div>
            }
            closable={tabs.length > 0}
            closeIcon={
              <TabOptions 
                currentTab={tab} 
                tabs={tabs}
                setActiveId={setActiveId}
                handleDoubleClick={handleDoubleClick}
                closeTab={closeTab}
                openNewTab={openNewTab}
                showQuerySettingsModal={showQuerySettingsModal}
                showSaveQueryModal={showSaveQueryModal}
                showOpenQueryModal={showOpenQueryModal}
                showShareAudienceModal={showShareAudienceModal}
                saveQueryToDb={saveQueryToDb}
                tabMode={tabMode}
                updateSchemaVersion={updateSchemaVersion}
              />
            }
          >
            {children(tab, handleTabSave)}
            {drilldownConfig.query ? (
              <DrilldownModal
                query={drilldownConfig.query}
                pivotConfig={drilldownConfig.pivotConfig}
                onClose={handleDrilldownModalClose}
              />
            ) : null}
          </TabPane>
        ))}
      </StyledTabs>
    </Fragment>
  );
}