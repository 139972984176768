import { DatePicker } from 'antd';
import { useState } from 'react';

import moment from 'moment';


const { RangePicker } = DatePicker;


export default function Calendar({
    values,
    onChange,
    disabled = false,
    rangeLimited = false,
    allowClear = true,
  }) {
    const [dates, setDates] = useState(null);
    
    const disabledDate = (current) => {
      if (!dates || !rangeLimited) {
        return false;
      }
      const tooLate = dates[0] && current.diff(dates[0], 'days') > 365;
      const tooEarly = dates[1] && dates[1].diff(current, 'days') > 365;
      return !!tooEarly || !!tooLate;
    };
  
    return (
      <RangePicker
        key="filter"
        format="YYYY-MM-DD"
        disabled={disabled}
        value={dates || values?.map(val => moment(val))}
        onChange={onChange}
        onCalendarChange={(val) => val ? setDates(val) : setDates([null, null])}
        disabledDate={disabledDate}
        allowClear={allowClear}
      />
    );
  }