import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Card, Alert } from '../atoms';
import { Typography, Spin, message } from 'antd';
import LoginSteps from '../components/LoginSteps';

import {
  useDeepEffect,
  useSecurityContext,
  useLocalStorage
} from '../hooks';

const { Title } = Typography;


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}


export function OauthRedirectPage() {
  
  const query = useQuery();
  const { push } = useHistory();
  const { token: securityContextToken, saveToken } = useSecurityContext()
  const [savedCode, setCode, removeCode] = useLocalStorage('code_verifier', null);
  const [showSpin, setShowSpin] = useState(true);


  useDeepEffect(() => {
      const code = query.get('code');
      if (code){
        onGoogleRedirect(code).then(res => {
          removeCode(savedCode);
          saveToken(res.access_token).then(res => {
            push('/');
            message.success("You're all set! Enjoy! 🚀")
          }).catch(err => console.error(err))
        }).catch(err => {
          setShowSpin(false);
          console.error(err)
        })
      } else {
        setShowSpin(false);
      }
  }, [securityContextToken]);


  async function onGoogleRedirect(code) {
    return fetch(
      `${process.env.REACT_APP_OAUTH_BASE_URL}/token`, 
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          code,
          client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
          client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
          grant_type: 'authorization_code',
          redirect_uri: process.env.REACT_APP_OAUTH_REDIRECT_URI,
          code_verifier: savedCode
        }),
      }
    ).then(res => {
      const json = res.json();
      return json;
    }).catch(err => console.error(err));
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center'}}>
       <LoginSteps />
      <Card style={{width: '340px', marginBottom: '76px', paddingBottom: '24px'}}>
        <div style={{display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems:'center'}}>
          <img src={`https://avatars.githubusercontent.com/u/52467369?s=200&v=4`} alt="cube logo" width='220'/>
          <Title style={{marginTop: '24px'}}level={5}> Cube authorization grant in progress... </Title>
          {showSpin ? <Spin /> : <Alert message="Something went wrong while trying to authorize... Please raise in #exodus slack channel" type="error" showIcon/>}
        </div>
      </Card>
    </div>
  );
}