import { useHistory } from 'react-router-dom';

import { QueryBuilderContainer } from '../components/Explore/QueryBuilderContainer';

export function ExplorePage() {
  
  const { push } = useHistory();

  function setQueryParam({ query }) {
    if (query) {
      push({ search: `?query=${encodeURIComponent(JSON.stringify(query))}` });
    }
  }

  return (
    <QueryBuilderContainer
      schemaVersion={0}
      onVizStateChanged={setQueryParam}
      onTabChange={setQueryParam}
      mode="query"
    />
  );
}