import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';



export const AboutData = ({description}) => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button 
        type="secondary" 
        shape="round" 
        onClick={showModal}
        >
        About the data
      </Button>
      <Modal 
        title="About the data" 
        open={isModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel}
        footer={null}
        width="920px"
        >
        <ReactMarkdown linkTarget="_blank">{description}</ReactMarkdown>
      </Modal>
    </>
  );
};