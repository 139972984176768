import { useEffect } from 'react';

import { useDeepDependencies } from './deep-dependencies';

export function useDeepEffect(
  callback,
  dependencies
) {
  const memoizedDependencies = useDeepDependencies(dependencies);
  return useEffect(callback, memoizedDependencies);
}