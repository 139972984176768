import { PlusOutlined } from '@ant-design/icons';
import { Menu, Row, Col } from 'antd';
import moment from 'moment';
import { useState, Fragment } from 'react';
import styled from 'styled-components';

import ButtonDropdown from './ButtonDropdown';
import MemberDropdown from './MemberDropdown';
import RemoveButtonGroup from './RemoveButtonGroup';
import MissingMemberTooltip from './MissingMemberTooltip';
import SectionRow from '../SectionRow';
import Calendar from '../../atoms/Calendar';


const Label = styled.div`
  color: var(--dark-04-color);
  line-height: 32px;
`;

const DateRanges = [
  { title: 'Custom', value: 'custom' },
  { title: 'All time', value: undefined },
  { value: 'Today' },
  { value: 'Yesterday' },
  { value: 'This week' },
  { value: 'This month' },
  { value: 'This quarter' },
  { value: 'This year' },
  { value: 'Last 3 days' },
  { value: 'Last 7 days' },
  { value: 'Last 14 days' },
  { value: 'Last 30 days' },
  { value: 'Last week' },
  { value: 'Last 2 weeks' },
  { value: 'Last 3 weeks' },
  { value: 'Last 13 weeks' },
  { value: 'Last month' },
  { value: 'Last 3 months' },
  { value: 'Last 6 months' },
  { value: 'Last 9 months' },
  { value: 'Last 12 months' },
  { value: 'Last quarter' },
  { value: 'Last year' },
];

const TimeGroup = ({
  mode,
  members = [],
  disabled = false,
  availableMembers,
  missingMembers,
  addMemberName,
  updateMethods,
  makeTimeGroupUnclosable,
}) => {
  const [isRangePickerVisible, toggleRangePicker] = useState([false]);

  const handleToggleRangePicker = (index, value) => {
    const newChecked = [...isRangePickerVisible];
    newChecked[index] = value;
    toggleRangePicker(newChecked);
  };


  function onDateRangeSelect(m, dateRange) {
    if (dateRange && !dateRange.some((d) => !d)) {
      updateMethods.update(m, {
        ...m,
        dateRange: dateRange.map((dateTime) => dateTime.format('YYYY-MM-DD')),
      });
    }
  }

  const granularityMenu = (member, onClick) => 
    (
    <Menu>
      {member.granularities.length ? (
        member.granularities.filter(m => !['second', 'minute', 'hour'].includes(m.name)).map((m) => (
          <Menu.Item key={m.title} onClick={() => onClick(m)}>
            {m.title}
          </Menu.Item>
        ))
      ) : (
        <Menu.Item disabled>No members found</Menu.Item>
      )}
    </Menu>
    );

  const dateRangeMenu = (dimensionName, onClick) => (
    <Menu>
      {DateRanges.map((m) => {
        if (!['GA.date', 'Events.date'].includes(dimensionName) || (m.title || m.value) !== 'All time')
          return <Menu.Item key={m.title || m.value} onClick={() => onClick(m)}>
            {m.title || m.value}
          </Menu.Item>
      })}
    </Menu>
  );

  return (
    <SectionRow>
      {members.map((m, index) => {
        const isMissing = missingMembers.includes(m.dimension.title);
        m.dimension.granularities = mode === 'retention' ? m.dimension.granularities.filter(g => ['day', 'week', 'month'].includes(g.name)) : m.dimension.granularities;
        const buttonGroup = (
          <RemoveButtonGroup
            disabled={disabled}
            className={disabled ? 'disabled' : null}
            color={isMissing ? 'danger' : 'primary'}
            onRemoveClick={() => updateMethods.remove(m)}
            enableClose={makeTimeGroupUnclosable && (m.dimension.name === 'RegisteredUsers.registration_date' || mode === 'retention') ?  false : true}
          >
            <MemberDropdown
              data-testid="TimeDimension"
              disabled={disabled}
              availableCubes={availableMembers}
              onClick={(updateWith) =>
                updateMethods.update(m, { ...m, dimension: updateWith })
              }
            >
              {m.dimension.shortTitle}
            </MemberDropdown>
          </RemoveButtonGroup>
        );

        return (
          <Row gutter={6} key={index} style={index !== members.length -1 ? {'paddingRight': 8} : {}}>
            <Col>
              {isMissing ? (
                <MissingMemberTooltip>{buttonGroup}</MissingMemberTooltip>
              ) : (
                buttonGroup
              )}
            </Col>
            <Col>
              <Label>for</Label>
            </Col>

            <Col>
              <ButtonDropdown
                disabled={disabled || mode === 'retention'}
                overlay={dateRangeMenu(m.dimension.name,  (dateRange) => {
                  if (dateRange.value === 'custom') {
                    handleToggleRangePicker(index, true);
                  } else {
                    updateMethods.update(m, {
                      ...m,
                      dateRange: dateRange.value,
                    });
                    handleToggleRangePicker(index, false);
                  }
                })}
              >
                {('dateRange' in m && Array.isArray(m.dateRange)) || isRangePickerVisible[index] ? 'Custom'
                  : m.dateRange || 'All time'}
              </ButtonDropdown>
            </Col>

            {('dateRange' in m && Array.isArray(m.dateRange)) || isRangePickerVisible[index] ? (
              <Col>
                <Calendar
                  values={('dateRange' in m && Array.isArray(m.dateRange) ? m.dateRange : [] || []).map((date) => moment(date))}
                  disabled={disabled}
                  onChange={(dateRange) => onDateRangeSelect(m, dateRange)}
                  rangeLimited={['GA.date', 'Events.date'].includes(m.dimension.name)}
                  allowClear={!(mode === 'retention')}
                />
              </Col>
            ) : null}

            {mode !== "audience" && <Col>
              <Label>{' by '}</Label>
            </Col>}
            {mode !== "audience" && <Col>
              <ButtonDropdown
                disabled={disabled}
                overlay={granularityMenu(m.dimension, (granularity) =>
                  updateMethods.update(m, { ...m, granularity: granularity.name })
                )}
              >
                {m.dimension.granularities.find(
                  (g) => g.name === m.granularity
                ) &&
                  m.dimension.granularities.find((g) => g.name === m.granularity)
                    .title}
              </ButtonDropdown>
            </Col>}
          </Row>
        );
      })}

      
        {mode !== 'retention' && <MemberDropdown
          data-testid="TimeDimension"
          disabled={disabled}
          availableCubes={availableMembers}
          type="dashed"
          icon={<PlusOutlined />}
          onClick={(member) =>
            updateMethods.add({ dimension: member })
          }
        >
          {!members.length && addMemberName}
        </MemberDropdown>}
    </SectionRow>
  );
};

export default TimeGroup;